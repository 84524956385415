import {inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';
import {DialogInterface} from '@app/services/dialog/dialog-interface';

export const BROWSER_DIALOG = new InjectionToken<DialogInterface>('Browser Dialog', {
  providedIn: 'root',
  factory: () => ({
    confirm: window.confirm,
  }),
});

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly dialog = inject(BROWSER_DIALOG);

  confirm(message?: string): Observable<boolean> {
    const confirmation = this.dialog.confirm(message || 'Is it OK?');

    return of(confirmation);
  }
}
