import {Component, OnInit} from '@angular/core';
import {StepFormService} from '../services/step-form.service';
import {AbstractFormListComponent} from '../abstract-form-list/abstract-form-list.component';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {StepComponent} from '../step/step.component';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'app-step-list',
    templateUrl: './step-list.component.html',
    styleUrls: ['./step-list.component.css'],
    standalone: true,
    imports: [NgFor, StepComponent, ReactiveFormsModule, NgIf, MatIconModule]
})
export class StepListComponent extends AbstractFormListComponent implements OnInit {
  constructor(formService: StepFormService) {
    super(formService);
  }

  ngOnInit(): void {
    // this fixes NG0100: ExpressionChangedAfterItHasBeenCheckedError (https://blog.angular-university.io/angular-debugging/)
    setTimeout(() => {
      this.add();
    });
  }

  addOnLastStepDirty(isLast: boolean): void {
    if (isLast) {
      this.add();
    }
  }

  public up(number: number) {
    if (number > 0) {
      this.swapSteps(number, number - 1);
    }
  }

  public down(number: number) {
    if (number < this.formArray.length - 1) {
      this.swapSteps(number, number + 1);
    }
  }

  private swapSteps(firstNumber: number, secondNumber: number) {
    const firstStep = this.formArray.at(firstNumber);
    const secondStep = this.formArray.at(secondNumber);


    this.formArray.removeAt(firstNumber);
    this.formArray.insert(firstNumber, secondStep);

    this.formArray.removeAt(secondNumber);
    this.formArray.insert(secondNumber, firstStep);
  }
}
