import {Injectable} from '@angular/core';
import {MarkedAdapterService} from '@app/view-recipe/services/marked-adapter/marked-adapter.service';

@Injectable({
  providedIn: 'root',
})
export class MarkdownParserService {
  private parser: MarkedAdapterService;

  constructor(parser: MarkedAdapterService) {
    this.parser = parser;
  }

  public parse(text: string): string {
    return this.parser.parse(text);
  }
}
