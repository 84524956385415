<h4>Yield</h4>
<div
  *ngFor="let yieldGroup of formArrayFormGroups; let i = index; let last = last"
  class="yield-list-item-container"
>
  <app-yield
    [formGroup]="yieldGroup"
    class="form-field"
    [class.last]="last"
  ></app-yield>

  <button type="button" class="button icon-button remove-yield-button" (click)="remove(i)">
    <mat-icon>remove</mat-icon>
  </button>
  <button *ngIf="last" type="button" class="button icon-button add-yield-button" (click)="add()">
    <mat-icon>add</mat-icon>
  </button>
</div>

