import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Step from '@app/model/step.model';
import {TaskFormService} from './task-form.service';
import {ComponentFormService} from './component-form.service';
import {BaseFormService} from './base-form-service';

@Injectable({
  providedIn: 'root'
})
export class StepFormService implements BaseFormService {
  private builder: UntypedFormBuilder;
  private taskFormService: TaskFormService;
  private componentFormService: ComponentFormService;

  constructor(builder: UntypedFormBuilder, taskFormService: TaskFormService, componentFormService: ComponentFormService) {
    this.builder = builder;
    this.taskFormService = taskFormService;
    this.componentFormService = componentFormService;
  }

  public init(steps: Step[]): UntypedFormGroup[] {
    return steps.map(step => {
      const group = this.createGroup();

      group.patchValue({
        id: step.id,
        number: step.number,
      });

      const tasksFormControl = this.taskFormService.createControl(step.tasks);
      group.setControl('tasks', tasksFormControl);

      const components = this.componentFormService.init(step.components);
      group.setControl('components', this.builder.array(components));

      return group;
    });
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      number: ['', [ Validators.required, Validators.max(32_767) ]],
      tasks: ['', Validators.required],
      components: this.builder.array([]),
    });
  }

  public createStep(formGroupValues): Step {
    return Step.fromStepInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      number: Number.parseInt(formGroupValues.number, 10),
      tasks: this.taskFormService.createTasks(formGroupValues.tasks),
      components: formGroupValues.components
        .filter(componentFormValues => !this.componentFormService.isEmpty(componentFormValues))
        .map(component => this.componentFormService.createComponent(component))
    });
  }

  isEmpty(formGroupValues): boolean {
    return formGroupValues.tasks === '' &&
      formGroupValues.components.reduce((previousValue: boolean, componentFormGroupValues) =>
        previousValue && this.componentFormService.isEmpty(componentFormGroupValues),
        true
      )
    ;
  }
}
