import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectAllViewedRecipeIds} from '@app/store/selectors/view-recipe-page.selector';
import {ViewRecipePageState} from '@app/store/reducers/view-recipe-page.reducer';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import Recipe from '@app/model/recipe.model';
import {selectRecipesByIds} from '@app/store/selectors/recipe.selector';
import {viewRecipePageActions} from '@app/store/actions/view-recipe-page.actions';
import {PathNames} from '@app/app.routes';
import {AsyncPipe, NgFor} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    standalone: true,
    imports: [RouterLink, MatIconModule, NgFor, AsyncPipe]
})
export class NavigationComponent implements OnInit {
  protected readonly PathNames = PathNames;

  public recipes$: Observable<Recipe[]>;

  constructor(
    private store: Store<ViewRecipePageState>
  ) {}

  ngOnInit(): void {
    this.recipes$ = this.store.pipe(
      select(selectAllViewedRecipeIds),
      switchMap(recipeIds => this.store.select(selectRecipesByIds([...recipeIds]))),
    );
  }

  close(recipe: Recipe): void {
    this.store.dispatch(viewRecipePageActions.recipeViewPageClosed({ recipeId: recipe.id }));
  }
}
