import {Component, OnInit} from '@angular/core';
import {AbstractFormListComponent} from '@app/edit-recipe/abstract-form-list/abstract-form-list.component';
import {YieldFormService} from '@app/edit-recipe/services/yield-form.service';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {YieldComponent} from '../yield/yield.component';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'app-yield-list',
    templateUrl: './yield-list.component.html',
    styleUrls: ['./yield-list.component.css'],
    standalone: true,
    imports: [NgFor, YieldComponent, ReactiveFormsModule, MatIconModule, NgIf]
})
export class YieldListComponent extends AbstractFormListComponent implements OnInit {

  constructor(formService: YieldFormService ) {
    super(formService);
  }

  ngOnInit(): void {
    // this fixes NG0100: ExpressionChangedAfterItHasBeenCheckedError (https://blog.angular-university.io/angular-debugging/)
    setTimeout(() => {
      this.add(false);
    });
  }
}
