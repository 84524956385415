import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import MeasuringUnit from '@app/model/measuring-unit.model';
import {environment} from '../../../environments/environment';
import MeasuringUnitRequestInterface from '@app/services/api/measuring-unit-request-interface';
import {MeasuringUnitMapperService} from '@app/services/mapper/measuring-unit-mapper.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export default class MeasuringUnitApiService {
  private readonly httpClient: HttpClient;

  private readonly mapper: MeasuringUnitMapperService;

  private readonly URL: string;
  private readonly V2URL: string;

  constructor(httpClient: HttpClient, mapper: MeasuringUnitMapperService) {
    this.httpClient = httpClient;
    this.mapper = mapper;
    this.URL = environment.apiUrl + '/measuringUnits';

    // [test-api-platform] url for api v2
    this.V2URL = environment.apiV2Url + '/measuring_units';
  }

  public getMeasuringUnits(): Observable<MeasuringUnit[]> {
    const url = this.V2URL;

    return this.httpClient
      .get<MeasuringUnitRequestInterface[]>(url)
      .pipe(
        map(requests => requests.map(
          request => this.mapper.mapRequestToModel(request)
        )),
        map(units => units.map(
          unit => MeasuringUnit.fromMeasuringUnitInterface(unit))
        ),
      );
  }

  public createMeasuringUnit(measuringUnit: MeasuringUnit): Observable<MeasuringUnit> {
    const request = this.mapper.mapModelToRequest(measuringUnit)

    return this.httpClient
      .post<MeasuringUnitRequestInterface>(this.URL, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(unit => MeasuringUnit.fromMeasuringUnitInterface(unit)),
      );
  }

  public editMeasuringUnit(id: number, measuringUnit: MeasuringUnit): Observable<MeasuringUnit> {
    const request = this.mapper.mapModelToRequest(measuringUnit)

    return this.httpClient
      .put<MeasuringUnitRequestInterface>(`${this.URL}/${id}`, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(unit => MeasuringUnit.fromMeasuringUnitInterface(unit)),
      );
  }

  public deleteMeasuringUnit(id: number): Observable<MeasuringUnit> {
    return this.httpClient
      .delete<MeasuringUnitRequestInterface>(`${this.URL}/${id}`)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(unit => MeasuringUnit.fromMeasuringUnitInterface(unit)),
      );
  }
}
