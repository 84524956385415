<ol id="navigation-list">
  <li class="list-recipes-element element">
    <a
      [routerLink]="PathNames.list"
      class="list-recipes-link"
    >
      <mat-icon inline="true">list</mat-icon>
    </a>
  </li>
  <li *ngFor="let recipe of recipes$ | async" class="view-recipe-element element">
    <a
      [routerLink]="[PathNames.view, recipe.id]"
      class="view-recipe-link"
    >
      {{ recipe.title }}
    </a>
    <button
      (click)="close(recipe)"
      class="close-button"
    >
      <mat-icon inline="true" class="close-button-icon">close</mat-icon>
    </button>
  </li>
</ol>
