import {Component, Input, OnInit} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FilterService} from '@app/services/filter/filter.service';
import ComponentProperty from '@app/model/component-property.model';
import {MatOptionModule} from '@angular/material/core';
import {AsyncPipe, NgFor} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@Component({
    selector: 'app-select-component-property',
    templateUrl: './select-component-property.component.html',
    styleUrls: ['./select-component-property.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, MatAutocompleteModule, NgFor, MatOptionModule, AsyncPipe]
})
export class SelectComponentPropertyComponent implements OnInit {
  private readonly selectionMaxSize = 5;

  private filterService: FilterService;

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  @Input({ required: true })
  public properties$: Observable<ComponentProperty[]>;

  public filteredProperties$: Observable<string[]>;

  constructor(filterService: FilterService) {
    this.filterService = filterService;
  }

  ngOnInit(): void {
    const inputValueAndProperties$ = combineLatest([
      this.nameFormControl.valueChanges,
      this.properties$
    ]);

    this.filteredProperties$ = inputValueAndProperties$.pipe(
      tap(([inputValue, properties]) => {
        const propertyId = properties.find(p => p.name === inputValue)?.id || '';
        this.formGroup.patchValue({ id: propertyId });
      }),
      map(([inputValue, properties]) =>
        this.filterService.filter(
          inputValue,
          properties.map(property => property.name),
          this.selectionMaxSize
        )
      )
    );
  }

  get nameFormControl(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }
}
