import {createActionGroup, props} from '@ngrx/store';

export const viewRecipePageActions = createActionGroup({
  source: "ViewRecipePage",
  events: {
    "Recipe Viewed": props<{ recipeId: number, viewedAt: Date }>(),
    "Recipe View Page closed": props<{ recipeId: number }>(),
    "Active recipes yield changed": props<{ scaleFactor: number }>(),
    "Recipes yield changed": props<{ recipeId: number; scaleFactor: number }>(),
    "Recipes scroll position changed": props<{
      recipeId: number;
      scrollPosition: number;
    }>(),
  },
});
