import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ScaleYieldFormDto} from '@app/view-recipe/types/scale-yield-form-dto';
import {ScaleYieldFormChangedEvent} from '@app/view-recipe/types/scale-yield-form-changed-event';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
    selector: 'app-scale-yield',
    templateUrl: './scale-yield.component.html',
    styleUrls: ['./scale-yield.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule]
})
export class ScaleYieldComponent implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();

  @Input({ required: true })
  public scaleYieldFormDTO: ScaleYieldFormDto;

  @Output()
  public changed = new EventEmitter<ScaleYieldFormChangedEvent>();

  ngOnInit(): void {
    this.scaleYieldFormDTO.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe(changes => this.changed.emit({
        "originalQuantity": this.scaleYieldFormDTO.originalQuantity,
        "quantity": changes.quantity,
      }));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
