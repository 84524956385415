<input
  type="text"
  title="Komponente"
  class="select-component-property-input"
  [formControl]="nameFormControl"
  [matAutocomplete]="auto"
>
<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  disableRipple
>
  <mat-option
    *ngFor="let option of filteredProperties$ | async"
    [value]="option"
    class="property-option"
  >
    {{option}}
  </mat-option>
</mat-autocomplete>
