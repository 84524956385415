import {createActionGroup, emptyProps, props} from '@ngrx/store';
import Ingredient from '@app/model/ingredient.model';

export const ingredientActions = createActionGroup({
    source: 'Ingredient',
    events: {
        'Load All Ingredients': emptyProps(),
        'Load All Ingredients Success': props<{ data: Ingredient[] }>(),
        'Load All Ingredients Failure': props<{ error: any }>(),
        'Add Ingredient': props<{ data: Ingredient }>(),
        'Add Ingredient Success': props<{ data: Ingredient }>(),
        'Add Ingredient Failure': props<{ error: any }>()
    }
});
