import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {recipeActions} from '@app/store/actions/recipe.actions';
import RecipeDataService from '../services/recipe-data/recipe-data.service';

@Injectable()
export class RecipeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dataService: RecipeDataService,
    private readonly router: Router,
  ) {}

  loadRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.loadAllRecipes),
      concatMap(() =>
        this.dataService.getAll().pipe(
          map((data) => recipeActions.loadAllRecipesSuccess({ data })),
          catchError((error) =>
            of(recipeActions.loadAllRecipesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  addRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.addRecipe),
      map(action => action.data),
      concatMap(recipe =>
        this.dataService.save(recipe).pipe(
          map(data => recipeActions.addRecipeSuccess( { data })),
          catchError(error => of(recipeActions.addRecipeFailure({ error }))))
      )
    ),
  );

  gotoList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.addRecipeSuccess),
      tap(() => this.router.navigate(['/']))
    ),
  {dispatch: false}
  );
}
