import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Recipe from '@app/model/recipe.model';
import {StepFormService} from './step-form.service';
import {KeywordFormService} from '@app/edit-recipe/services/keyword-form.service';
import {YieldFormService} from '@app/edit-recipe/services/yield-form.service';

@Injectable({
  providedIn: 'root'
})
export class RecipeFormService {
  private builder: UntypedFormBuilder;
  private stepFormService: StepFormService;
  private keywordFormService: KeywordFormService;
  private yieldFormService: YieldFormService;

  constructor(builder: UntypedFormBuilder, stepFormService: StepFormService, keywordFormService: KeywordFormService, yieldFormService: YieldFormService) {
    this.builder = builder;
    this.stepFormService = stepFormService;
    this.keywordFormService = keywordFormService;
    this.yieldFormService = yieldFormService;
  }

  public init(recipe: Recipe): UntypedFormGroup {
    const group = this.createGroup();

    group.patchValue({
      id: recipe.id,
      title: recipe.title,
      titleTranslation: recipe.titleTranslation,
      description: recipe.description,
      source: recipe.source,
      cookingTime: recipe.cookingTime,
      preparationTime: recipe.preparationTime,
      updatedAt: recipe.updatedAt,
      createdAt: recipe.createdAt,
    });

    const steps = this.stepFormService.init(recipe.steps);
    group.setControl('steps', this.builder.array(steps));

    const keywords = this.keywordFormService.init(recipe.keywords);
    group.setControl('keywords', this.builder.array(keywords));

    const yields = this.yieldFormService.init(recipe.yield);
    group.setControl('yield', this.builder.array(yields));

    return group;
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      title: ['', [ Validators.required, Validators.maxLength(127) ]],
      titleTranslation: ['', Validators.maxLength(127)],
      description: ['', Validators.maxLength(8192)],
      source: ['', Validators.maxLength(255)],
      cookingTime: [
        '',
        [
          Validators.min(0),
          Validators.max(32_767),
        ],
      ],
      preparationTime: [
        '',
        [
          Validators.min(0),
          Validators.max(32_767),
        ],
      ],
      steps: this.builder.array([]),
      keywords: this.builder.array([]),
      yield: this.builder.array([]),
      updatedAt: '',
      createdAt: '',
    });
  }

  public createRecipe(formGroupValues): Recipe {
    return  Recipe.fromRecipeInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      title: formGroupValues.title,
      titleTranslation: formGroupValues.titleTranslation,
      description: formGroupValues.description,
      source: formGroupValues.source,
      cookingTime: Number.parseInt(formGroupValues.cookingTime, 10),
      preparationTime: Number.parseInt(formGroupValues.preparationTime, 10),
      updatedAt: formGroupValues.updatedAt,
      createdAt: formGroupValues.createdAt,
      steps: formGroupValues.steps
        .filter(stepFormValues => !this.stepFormService.isEmpty(stepFormValues))
        .map(step => this.stepFormService.createStep(step)),
      keywords: formGroupValues.keywords
        .filter(keywordFormValues => !this.keywordFormService.isEmpty(keywordFormValues))
        .map(keyword => this.keywordFormService.createKeyword(keyword)),
      yield: formGroupValues.yield
        .filter(yieldFormValues => !this.yieldFormService.isEmpty(yieldFormValues))
        .map(yieldObject => this.yieldFormService.createYield(yieldObject))
    });
  }
}
