<div id="app">
  <div id="content">
    <nav>
      <app-navigation></app-navigation>
    </nav>
    <main>
      <router-outlet></router-outlet>
    </main>
    <footer>
      <app-development-bar />
    </footer>
  </div>
</div>
