import MeasuringUnit, {MeasuringUnitInterface} from './measuring-unit.model';

export interface YieldInterface {
  id?: number | null;
  quantity: number;
  measuringUnit: MeasuringUnitInterface;
}
export default class Yield implements YieldInterface{
  public id: number | null;
  public quantity: number;
  public measuringUnit: MeasuringUnit;

  public static fromYieldInterface(yieldInterface: YieldInterface): Yield {
    const yieldObject = new Yield();

    yieldObject.id = yieldInterface.id || null;
    yieldObject.quantity = yieldInterface.quantity;
    yieldObject.measuringUnit = MeasuringUnit.fromMeasuringUnitInterface(yieldInterface.measuringUnit);

    return yieldObject;
  }
}
