import {Injectable} from '@angular/core';
import MeasuringUnit, {MeasuringUnitInterface} from '@app/model/measuring-unit.model';
import MeasuringUnitRequestInterface from '@app/services/api/measuring-unit-request-interface';

@Injectable({
  providedIn: 'root'
})
export class MeasuringUnitMapperService {
  public mapModelToRequest(measuringUnit: MeasuringUnit): MeasuringUnitRequestInterface {
    return {
      id: measuringUnit.id,
      name: measuringUnit.name,
    };
  }

  public mapRequestToModel(request: MeasuringUnitRequestInterface): MeasuringUnit {
    return MeasuringUnit.fromMeasuringUnitInterface(MeasuringUnitMapperService.mapRequestToMeasuringUnit(request));
  }

  private static mapRequestToMeasuringUnit(request: MeasuringUnitRequestInterface): MeasuringUnitInterface {
    return {
      id: request.id,
      name: request.name,
    };
  }
}
