<div
  *ngFor="let stepGroup of formArrayFormGroups; let i = index; let last = last; let first = first"
  class="step-list-item"
  [class.last]="last"
>
  <app-step
    (markedDirty)="addOnLastStepDirty(last)"
    [formGroup]="stepGroup"
    [number]="i"
  ></app-step>

  <button *ngIf="!last" type="button" class="button icon-button" (click)="remove(i)">
    <mat-icon>remove</mat-icon>
  </button>
  <button *ngIf="!first" type="button" class="button icon-button move-step-up-button" (click)="up(i)">
    <mat-icon>arrow_upward</mat-icon>
  </button>
  <button *ngIf="!last" type="button" class="button icon-button move-step-down-button" (click)="down(i)">
    <mat-icon>arrow_downward</mat-icon>
  </button>
</div>
