import {Directive, Input} from '@angular/core';
import {BaseFormService} from '../services/base-form-service';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';

@Directive()
export abstract class AbstractFormListComponent {
  @Input({ required: true })
  public formArray: UntypedFormArray;

  private formService: BaseFormService;

  protected constructor(formService: BaseFormService) {
    this.formService = formService;
  }

  get formArrayFormGroups(): UntypedFormGroup[] {
    return this.formArray.controls as UntypedFormGroup[];
  }

  public add(emitEvent = true): void {
    this.formArray.push(
      this.formService.createGroup(),
      { emitEvent }
    );
  }

  public remove(index: number): void {
    this.formArray.removeAt(index);
    this.formArray.markAsDirty();

    if (this.formArray.length === 0) {
      this.add();
    }
  }

}
