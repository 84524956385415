import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import KeywordDataService from '../services/keyword-data/keyword-data.service';
import {keywordActions} from '../actions/keyword.actions';

@Injectable()
export class KeywordEffects {
  constructor(
    private actions$: Actions,
    private readonly dataService: KeywordDataService
  ) {}

  loadKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(keywordActions.loadAllKeywords),
      concatMap(() =>
        this.dataService.getAll().pipe(
          map(data => keywordActions.loadAllKeywordsSuccess({ data })),
          catchError(error => of(keywordActions.loadAllKeywordsFailure({ error }))))
      ),
    ),
  );

  addKeyword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(keywordActions.addKeyword),
      map(action => action.data),
      concatMap(keyword =>
        this.dataService.save(keyword).pipe(
          map(data => keywordActions.addKeywordSuccess( { data })),
          catchError(error => of(keywordActions.addKeywordFailure({ error }))))
      )
    ),
  );
}
