import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {KeywordState} from '@app/store/reducers/keyword.reducer';
import {selectAllKeywordNames, selectKeywordByName} from '@app/store/selectors/keyword.selector';
import {combineLatestWith, Observable, Subject} from 'rxjs';
import {filter, map, startWith, take, takeUntil} from 'rxjs/operators';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import Keyword from '@app/model/keyword.model';
import {keywordActions} from '@app/store/actions/keyword.actions';
import {AsyncPipe} from '@angular/common';
import {KeywordsComponent} from '../../shared/keywords/keywords.component';

@Component({
    selector: 'app-keywords-container',
    templateUrl: './keywords-container.component.html',
    styleUrls: ['./keywords-container.component.css'],
    standalone: true,
    imports: [KeywordsComponent, AsyncPipe]
})
export class KeywordsContainerComponent implements OnInit, OnDestroy {
  private readonly store: Store<KeywordState>;

  private readonly formBuilder: UntypedFormBuilder;

  private readonly destroy = new Subject<void>();

  @Input({ required: true })
  public keywordsForms: UntypedFormArray

  public allKeywordNames$: Observable<string[]>;

  public selectedKeywordNames$: Observable<string[]>;

  constructor(store: Store<KeywordState>, formBuilder: UntypedFormBuilder) {
    this.store = store;
    this.formBuilder = formBuilder;
  }

  ngOnInit(): void {

    this.selectedKeywordNames$ = this.keywordsForms.valueChanges.pipe(
      startWith(this.keywordsForms.value),
      map((keywordFormArrayValues: KeywordFormGroupValue[]) => keywordFormArrayValues.map(value => value.name))
    );

    this.allKeywordNames$ = this.store.pipe(
      select(selectAllKeywordNames),
      combineLatestWith(this.selectedKeywordNames$),
      map(([allKeywords, selectedKeywords]) => allKeywords.filter(keyword => !selectedKeywords.includes(keyword))),
    );
  }

  public addKeyword(name: string): void {
    const keyword = Keyword.fromKeywordInterface({
      name,
    });

    this.store.dispatch(keywordActions.addKeyword({
      data: keyword
    }));

    this.selectKeyword(name);
  }

  public selectKeyword(name: string): void {
    if (this.findIndexKeywordNameInFormArray(name) >= 0) {
      return;
    }

    this.store
      .pipe(
        select(selectKeywordByName(name)),
        filter(value => value !== undefined),
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe((keyword) =>
        this.keywordsForms.push(
          this.formBuilder.group({
            id: keyword.id,
            name: keyword.name,
          })
        )
      );
  }

  public deselectKeyword(name: string): void {
    const index = this.findIndexKeywordNameInFormArray(name);

    if (index >= 0) {
      this.keywordsForms.removeAt(index);
    }
  }

  private findIndexKeywordNameInFormArray(name: string): number {
    return this.keywordsForms.value.findIndex(value => value.name === name);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

interface KeywordFormGroupValue {
  id: number,
  name: string
}
