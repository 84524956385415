import {Routes} from '@angular/router';
import {ListRecipesContainerComponent} from '@app/list-recipes/list-recipes-container/list-recipes-container.component';
import {ViewRecipeContainerComponent} from '@app/view-recipe/view-recipe-container/view-recipe-container.component';
import {RecipeContainerComponent as EditRecipeContainerComponent} from '@app/edit-recipe/recipe-container/recipe-container.component';
import {canDeactivateGuard} from '@app/guards/can-deactivate.guard';

export enum PathNames {
  list = 'list',
  view = 'view',
  edit = 'edit',
  empty = '',
}
export const routes: Routes = [
  {
    path: PathNames.list,
    component: ListRecipesContainerComponent
  },
  {
    path: `${PathNames.view}/:id`,
    component: ViewRecipeContainerComponent
  },
  {
    path: PathNames.edit,
    component: EditRecipeContainerComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: `${PathNames.edit}/:id`,
    component: EditRecipeContainerComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: PathNames.empty,
    pathMatch: 'full',
    redirectTo: PathNames.list
  }
];

