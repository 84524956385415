import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import Recipe from '@app/model/recipe.model';
import {PathNames} from '@app/app.routes';
import {NgFor, NgIf} from '@angular/common';
import {KeywordsComponent} from '../../shared/keywords/keywords.component';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';

@Component({
    selector: 'app-list-recipes',
    templateUrl: './list-recipes.component.html',
    styleUrls: ['./list-recipes.component.css'],
    standalone: true,
    imports: [
        RouterLink,
        MatIconModule,
        KeywordsComponent,
        NgFor,
        NgIf,
    ],
})
export class ListRecipesComponent {
  protected readonly PathNames = PathNames;

  @Input({ required: true })
  public recipes: Recipe[];

  @Input({ required: true })
  public allKeywordNames: string[];

  @Input({ required: true })
  public selectedKeywordNames: string[];

  @Input({ required: true })
  public searchString: string;

  @Output()
  public keywordSelected = new BehaviorSubject<string>('');

  @Output()
  public keywordUnselected = new BehaviorSubject<string>('');

  @Output()
  public searchTermChanged = new EventEmitter<string>();

  getRecipeId(index: number, recipe: Recipe): number {
    return recipe.id;
  }

  public onSearchTermChanged(event: Event)  {
    this.searchTermChanged.emit((event.target as HTMLInputElement).value);
  }

}
