import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import Ingredient from '@app/model/ingredient.model';
import {environment} from '../../../environments/environment';
import {IngredientMapperService} from '@app/services/mapper/ingredient-mapper.service';
import {map} from 'rxjs/operators';
import IngredientRequestInterface from '@app/services/api/ingredient-request-interface';

@Injectable({
  providedIn: "root",
})
export default class IngredientApiService {
  private readonly httpClient: HttpClient;

  private readonly mapper: IngredientMapperService;

  private readonly URL: string;
  private readonly V2URL: string;

  constructor(httpClient: HttpClient, mapper: IngredientMapperService) {
    this.httpClient = httpClient;
    this.mapper = mapper;
    this.URL = environment.apiUrl + '/ingredients';

    // [test-api-platform] url for api v2
    this.V2URL = environment.apiV2Url + '/ingredients';
  }

  public getIngredients(): Observable<Ingredient[]> {
    const url = this.V2URL;

    return this.httpClient
      .get<IngredientRequestInterface[]>(url)
      .pipe(
        map(requests => requests.map(
          request => this.mapper.mapRequestToModel(request)
        )),
        map(ingredients => ingredients.map(
          ingredient => Ingredient.fromIngredientInterface(ingredient))
        ),
      );
  }

  public createIngredient(ingredient: Ingredient): Observable<Ingredient> {
    const request = this.mapper.mapModelToRequest(ingredient)

    return this.httpClient
      .post<IngredientRequestInterface>(this.URL, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(ingredient => Ingredient.fromIngredientInterface(ingredient)),
      );
  }

  public editIngredient(id: number, ingredient: Ingredient): Observable<Ingredient> {
    const request = this.mapper.mapModelToRequest(ingredient)

    return this.httpClient
      .put<IngredientRequestInterface>( `${this.URL}/${id}`, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(ingredient => Ingredient.fromIngredientInterface(ingredient)),
      );
  }

  public deleteIngredient(id: number): Observable<Ingredient> {
    return this.httpClient
      .delete<IngredientRequestInterface>(`${this.URL}/${id}`)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(ingredient => Ingredient.fromIngredientInterface(ingredient)),
      );
  }
}
