<ng-container [formGroup]="formGroup">
  <input
    type="text"
    title="Menge"
    class="quantity-input form-field"
    formControlName="quantity"
  />

  <app-measuring-unit
    class="form-field"
    [formGroup]="measuringUnitForm"
  ></app-measuring-unit>
</ng-container>
