import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import MeasuringUnit from '@app/model/measuring-unit.model';
import MeasuringUnitApiService from '@app/services/api/measuring-unit-api.service';

@Injectable({
  providedIn: 'root'
})
export default class MeasuringUnitDataService {

  private apiService: MeasuringUnitApiService;

  constructor(apiService: MeasuringUnitApiService) {
    this.apiService = apiService;
  }

  public getAll(): Observable<MeasuringUnit[]> {
    return this.apiService
      .getMeasuringUnits();
  }

  public save(measuringUnit: MeasuringUnit): Observable<MeasuringUnit> {
    return this.apiService.createMeasuringUnit(measuringUnit);
  }
}
