import {inject, Injectable, InjectionToken} from '@angular/core';

export const BROWSER_WAKE_LOCK = new InjectionToken<WakeLock>('Browser Wake Lock', {
  providedIn: 'root',
  factory: () => navigator.wakeLock,
});

@Injectable({
  providedIn: 'root'
})
export class ScreenWakeLockService {
  private readonly wakeLock = inject(BROWSER_WAKE_LOCK);

  private wakeLockSentinel: WakeLockSentinel = null;
  private isRequesting = false;

  public async request(): Promise<void> {
    if (this.wakeLockSentinel !== null || this.isRequesting) {
      return;
    }

    try {
      this.isRequesting = true;
      this.wakeLockSentinel = await this.wakeLock.request();
    } catch {
      return;
    } finally {
      this.isRequesting = false;
    }
  }

  public async release(): Promise<void> {
    if (this.wakeLockSentinel === null) {
      return;
    }

    const promise = this.wakeLockSentinel.release();
    this.wakeLockSentinel = null;
    await promise;
  }
}
