import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import HttpErrorService from '@app/services/http-error/http-error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly NUMBER_OF_RETRIES = 2;
  private readonly errorService: HttpErrorService;

  constructor(errorService: HttpErrorService) {
    this.errorService = errorService;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retry(this.NUMBER_OF_RETRIES),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = this.errorService.handleError(error);

          console.log(errorMessage);

          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
