import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {measuringUnitActions} from '../actions/measuring-unit.actions';
import MeasuringUnitDataService from '../services/measuring-unit-data/measuring-unit-data.service';

@Injectable()
export class MeasuringUnitEffects {
  constructor(
    private actions$: Actions,
    private readonly dataService: MeasuringUnitDataService
  ) {}

  loadMeasuringUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(measuringUnitActions.loadAllMeasuringUnits),
      concatMap(() =>
        this.dataService.getAll().pipe(
          map(data => measuringUnitActions.loadAllMeasuringUnitsSuccess({ data })),
          catchError(error => of(measuringUnitActions.loadAllMeasuringUnitsFailure({ error }))))
      ),
    ),
  );

  addMeasuringUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(measuringUnitActions.addMeasuringUnit),
      map(action => action.data),
      concatMap(unit =>
        this.dataService.save(unit).pipe(
          map(data => measuringUnitActions.addMeasuringUnitSuccess( { data })),
          catchError(error => of(measuringUnitActions.addMeasuringUnitFailure({ error }))))
      )
    ),
  );
}
