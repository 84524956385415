import {Action, createFeature, createReducer, on} from '@ngrx/store';
import {viewRecipePageActions} from '../actions/view-recipe-page.actions';

export const viewRecipePageFeatureKey = 'viewRecipePage';

export interface ViewRecipePageState {
  openedRecipes: readonly OpenedRecipe[];
}

interface OpenedRecipe {
  recipeId: number;
  scaleFactor: number;
  scrollPosition: number;
  firstTimeViewedAt: Date;
}

const initialOpenedRecipe: OpenedRecipe = {
  recipeId: undefined,
  scaleFactor: 1,
  scrollPosition: 0,
  firstTimeViewedAt: undefined,
}

export const initialState: ViewRecipePageState = {
  openedRecipes: [],
};

const reducer = createReducer(
  initialState,

  on(viewRecipePageActions.recipeViewed, (state, action) => {
    const isRecipeAlreadyOpened = state.openedRecipes.some(recipe => recipe.recipeId === action.recipeId);

    if (isRecipeAlreadyOpened) {
      return {
        openedRecipes: [...state.openedRecipes],
      };
    }

    return {
      openedRecipes: [
        ...state.openedRecipes.filter(recipe => recipe.recipeId !== action.recipeId),
        {
          ... initialOpenedRecipe,
          recipeId: action.recipeId,
          firstTimeViewedAt: action.viewedAt,
        }
      ]
    };
  }),

  on(viewRecipePageActions.recipeViewPageClosed, (state, action) => ({
    openedRecipes: state.openedRecipes.filter(recipe => recipe.recipeId !== action.recipeId),
  })),

  on(viewRecipePageActions.recipesYieldChanged, (state, action) => {
    const openedRecipe = state.openedRecipes.find(recipe => recipe.recipeId === action.recipeId);

    if (!openedRecipe) {
      return {
        openedRecipes: [...state.openedRecipes],
      };
    }

    const openedRecipes = state.openedRecipes.filter(recipe => recipe.recipeId !== action.recipeId);
    openedRecipes.push({
      ...openedRecipe,
      recipeId: action.recipeId,
      scaleFactor: action.scaleFactor,
    });

    return {
      openedRecipes
    };
  }),

  on(viewRecipePageActions.recipesScrollPositionChanged, (state, action) => {
    const openedRecipe = state.openedRecipes.find(recipe => recipe.recipeId === action.recipeId);

    if (!openedRecipe) {
      return {
        openedRecipes: [...state.openedRecipes],
      };
    }

    const openedRecipes = state.openedRecipes.filter(recipe => recipe.recipeId !== action.recipeId);
    openedRecipes.push({
      ...openedRecipe,
      scrollPosition: action.scrollPosition,
    });

    return {
      openedRecipes
    };
  }),
);

export const viewRecipePageFeature = createFeature({
  name: viewRecipePageFeatureKey,
  reducer,
})

export function viewRecipePageReducer(state: ViewRecipePageState | undefined, action: Action): ViewRecipePageState {
  return reducer(state, action);
}
