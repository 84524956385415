import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public filter(value: string, names: string[], selectionMaxSize: number): string[] {
    if (value.length === 0) {
      return [];
    }

    const filterValue = value.toLowerCase();

    return names
      .filter(option => option.toLowerCase().includes(filterValue))
      .slice(0, selectionMaxSize);
  }

  public includesIgnoringDiacritics(sentence: string, searchString: string): boolean {
    const sentenceWithoutDiacritics = this.replaceDiacriticMarks(sentence);
    const wordWithoutDiacritics = this.replaceDiacriticMarks(searchString);

    return sentenceWithoutDiacritics.toLowerCase().includes(wordWithoutDiacritics.toLowerCase());
  }

  private replaceDiacriticMarks(string: string): string {
    const form = "NFD"
    const regExp = /[\u0300-\u036F]/g;
    return string.normalize(form).replaceAll(regExp, "");
  }
}
