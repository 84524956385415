import ComponentProperty from './component-property.model';

export interface IngredientInterface {
  id?: number | null;
  name: string;
}

export default class Ingredient implements IngredientInterface, ComponentProperty {
  public id: number | null;
  public name: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static fromIngredientInterface(ingredientInterface: IngredientInterface): Ingredient {
    const ingredient= new Ingredient();
    ingredient.id = ingredientInterface.id || null;
    ingredient.name = ingredientInterface.name;
    return ingredient;
  }
}
