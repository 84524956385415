import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import ComponentProperty from '@app/model/component-property.model';
import {SaveComponentPropertyButtonComponent} from '../save-component-property-button/save-component-property-button.component';
import {SelectComponentPropertyComponent} from '../select-component-property/select-component-property.component';

@Component({
    selector: 'app-component-property',
    templateUrl: './component-property.component.html',
    styleUrls: ['./component-property.component.css'],
    standalone: true,
    imports: [SelectComponentPropertyComponent, ReactiveFormsModule, SaveComponentPropertyButtonComponent]
})
export class ComponentPropertyComponent implements OnInit {
  @Output()
  public saveProperty = new EventEmitter<string>();

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  @Input({ required: true })
  public properties$: Observable<ComponentProperty[]>;

  public propertyNames$: Observable<string[]>;

  ngOnInit(): void {
    this.propertyNames$ = this.properties$.pipe(
      map(properties => properties.map(property => property.name)),
    );
  }

  get nameFormControl(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }

  public onSaveProperty(name: string): void {
    this.saveProperty.emit(name);
  }
}
