import {NativeEffects} from '@app/store/effects/native.effects';
import {ViewEffects} from '@app/store/effects/view.effects';
import {RouterEffects} from '@app/store/effects/router.effects';
import {RecipeEffects} from '@app/store/effects/recipe.effects';
import {MeasuringUnitEffects} from '@app/store/effects/measuring-unit.effects';
import {KeywordEffects} from '@app/store/effects/keyword.effects';
import {IngredientEffects} from '@app/store/effects/ingredient.effects';
import {AppEffects} from '@app/store/effects/app.effects';

export const effects = [
  AppEffects,
  IngredientEffects,
  KeywordEffects,
  MeasuringUnitEffects,
  RecipeEffects,
  RouterEffects,
  ViewEffects,
  NativeEffects,
];
