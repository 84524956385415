import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import Keyword from '@app/model/keyword.model';
import {environment} from '../../../environments/environment';
import {KeywordMapperService} from '@app/services/mapper/keyword-mapper.service';
import {map} from 'rxjs/operators';
import KeywordRequestInterface from '@app/services/api/keyword-request-interface';

@Injectable({
  providedIn: "root",
})
export default class KeywordApiService {
  private readonly httpClient: HttpClient;

  private readonly mapper: KeywordMapperService;

  private readonly URL: string;
  private readonly V2URL: string;

  constructor(httpClient: HttpClient, mapper: KeywordMapperService) {
    this.httpClient = httpClient;
    this.mapper = mapper;
    this.URL = environment.apiUrl + '/keywords';

    // [test-api-platform] url for api v2
    this.V2URL = environment.apiV2Url + '/keywords';
  }

  public getKeywords(): Observable<Keyword[]> {
    const url = this.V2URL;

    return this.httpClient
      .get<KeywordRequestInterface[]>(url)
      .pipe(
        map(requests => requests.map(
          request => this.mapper.mapRequestToModel(request)
        )),
        map(keywords => keywords.map(
          keyword => Keyword.fromKeywordInterface(keyword))
        ),
      );
  }

  public createKeyword(keyword: Keyword): Observable<Keyword> {
    const request = this.mapper.mapModelToRequest(keyword)

    return this.httpClient
      .post<KeywordRequestInterface>(this.URL, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Keyword.fromKeywordInterface(recipe)),
      );
  }

  public editKeyword(id: number, keyword: Keyword): Observable<Keyword> {
    const request = this.mapper.mapModelToRequest(keyword)

    return this.httpClient
      .put<KeywordRequestInterface>(`${this.URL}/${id}`, request)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Keyword.fromKeywordInterface(recipe)),
      );
  }

  public deleteKeyword(id: number): Observable<Keyword> {
    return this.httpClient
      .delete<KeywordRequestInterface>(`${this.URL}/${id}`)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Keyword.fromKeywordInterface(recipe)),
      );
  }
}
