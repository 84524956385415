import {Action, createFeature, createReducer, on} from '@ngrx/store';
import {listRecipesPageActions} from '../actions/list-recipes-page.actions';

export const listRecipesPageFeatureKey = 'listRecipesPage';

export interface ListRecipesPageState {
  searchString: string;
  filterKeywords: ReadonlySet<string>;
  scrollPosition: number;
}

export const initialState: ListRecipesPageState = {
  searchString:  '',
  filterKeywords: new Set<string>([]),
  scrollPosition: 0,
};

const reducer = createReducer(
  initialState,
  on(listRecipesPageActions.searchStringChanged, (state, action) => ({
    ...state,
    searchString: action.searchString,
  })),
  on(listRecipesPageActions.filterKeywordAdded, (state, action) => ({
    ...state,
    filterKeywords: new Set<string>(state.filterKeywords).add(action.keywordName),
  })),
  on(listRecipesPageActions.filterKeywordRemoved, (state, action) => ({
    ...state,
    filterKeywords: new Set<string>([...state.filterKeywords].filter(keywordName => keywordName !== action.keywordName)),
  })),
  on(listRecipesPageActions.recipesScrollPositionChanged, (state, action) => ({
    ...state,
    scrollPosition: action.scrollPosition,
  })),
);

export const listRecipesPageFeature = createFeature({
  name: listRecipesPageFeatureKey,
  reducer,
});

export function listRecipesPageReducer(state: ListRecipesPageState | undefined, action: Action): ListRecipesPageState {
  return reducer(state, action);
}
