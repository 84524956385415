import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TaskFormService {
  private builder: UntypedFormBuilder;

  constructor(builder: UntypedFormBuilder) {
    this.builder = builder;
  }

  public createControl(tasks: string[]): UntypedFormControl {
   const taskLines = tasks
      .join('\n');

   return this.builder.control(taskLines);
  }

  public createTasks(tasks: string): string[] {
    return tasks
      .split('\n')
      .filter(line => line !== '')
    ;
  }
}
