import {ActionReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {ListRecipesPageState} from '@app/store/reducers/list-recipes-page.reducer';
import {environment} from '../../../environments/environment';

const localStorageKeyPrefix = environment.localStorageKeyPrefix;

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        listRecipesPage: {
          serialize: (state: ListRecipesPageState) => ({
            ...state,
            filterKeywords: [...state.filterKeywords],
          }),
        },
      },
      { viewRecipePage: ['openedRecipes'] },
    ],
    rehydrate: true,
    storageKeySerializer: key => `${localStorageKeyPrefix}_${key}`,
  })(reducer);
}
