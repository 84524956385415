import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export default class HttpErrorService {
  private static handleServerError(errorResponse: HttpErrorResponse): string {
    switch (errorResponse.status) {
      case 400: {
        return 'Bad Request.';
      }
      case 401: {
        return 'You need to log in to do this action.';
      }
      case 403: {
        return 'You don\'t have permission to access the requested resource.';
      }
      case 404: {
        return 'The requested resource does not exist.';
      }
      case 412: {
        return 'Precondition Failed.';
      }
      case 500: {
        return 'Internal Server Error.';
      }
      case 503: {
        return 'The requested service is not available.';
      }
      case 422: {
        return 'Validation Error!';
      }
      default: {
        return 'Something went wrong!';
      }
    }
  }

  public handleError(errorResponse: HttpErrorResponse): string {
    if (errorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return `An error occurred: ${errorResponse.error.message}`;
    }
    return HttpErrorService.handleServerError(errorResponse);
  }
}
