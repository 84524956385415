import {Component, isDevMode} from '@angular/core';
import {environment} from '../../environments/environment';
import {NgIf} from '@angular/common';
import {LocalStorageService} from '@app/services/local-storage/local-storage.service';

@Component({
  selector: 'app-development-bar',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './development-bar.component.html',
  styleUrl: './development-bar.component.css'
})
export class DevelopmentBarComponent {
  protected readonly apiUrl: string;
  protected readonly isDevMode = isDevMode();
  private readonly localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    this.localStorageService = localStorageService;
    this.apiUrl = environment.apiUrl;
  }

  clear() {
    this.localStorageService.clear();
  }
}
