import {Injectable} from '@angular/core';
import {Marked} from 'marked';

@Injectable({
  providedIn: 'root'
})
export class MarkedAdapterService {
  private marked: Marked;

  constructor() {
    this.marked = new Marked({
      async: false,
    });
  }

  public parse(text: string): string {
    return this.marked.parse(text) as string;
  }
}
