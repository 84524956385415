import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MarkdownParserService} from '@app/view-recipe/services/markdown-parser/markdown-parser.service';

@Component({
  selector: 'app-description',
  standalone: true,
  imports: [],
  templateUrl: './description.component.html',
  styleUrl: './description.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class DescriptionComponent {
  private readonly parserService: MarkdownParserService;

  @Input({ required: true })
  public description: string;

  constructor(parserService: MarkdownParserService) {
    this.parserService = parserService;
  }

  public getParsedDescription(): string {
    return this.parserService.parse(this.description);
  }
}
