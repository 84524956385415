import {Injectable} from '@angular/core';
import KeywordRequestInterface from '@app/services/api/keyword-request-interface';
import Keyword, {KeywordInterface} from '@app/model/keyword.model';

@Injectable({
  providedIn: 'root'
})
export class KeywordMapperService {
  public mapModelToRequest(keyword: Keyword): KeywordRequestInterface {
    return {
      id: keyword.id,
      name: keyword.name,
    };
  }

  public mapRequestToModel(request: KeywordRequestInterface): Keyword {
    return Keyword.fromKeywordInterface(KeywordMapperService.mapRequestToKeyword(request));
  }

  private static mapRequestToKeyword(request: KeywordRequestInterface): KeywordInterface {
    return {
      id: request.id,
      name: request.name,
    };
  }
}
