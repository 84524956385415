import {Injectable} from '@angular/core';
import Ingredient, {IngredientInterface} from '@app/model/ingredient.model';
import IngredientRequestInterface from '@app/services/api/ingredient-request-interface';

@Injectable({
  providedIn: 'root'
})
export class IngredientMapperService {
  public mapModelToRequest(ingredient: Ingredient): IngredientRequestInterface {
    return {
      id: ingredient.id,
      name: ingredient.name,
    };
  }

  public mapRequestToModel(request: IngredientRequestInterface): Ingredient {
    return Ingredient.fromIngredientInterface(IngredientMapperService.mapRequestToIngredient(request));
  }

  private static mapRequestToIngredient(request: IngredientRequestInterface): IngredientInterface {
    return {
      id: request.id,
      name: request.name,
    };
  }
}
