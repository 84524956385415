import {Component, OnInit, Output} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {combineLatestWith, Observable, ReplaySubject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {cloneDeep} from 'lodash-es';
import Recipe from '@app/model/recipe.model';
import {RecipeState} from '@app/store/reducers/recipe.reducer';
import {SortService} from '@app/services/sort/sort.service';
import {RecipeScalingService} from '@app/view-recipe/services/recipe-scaling/recipe-scaling.service';
import {FormBuilder} from '@angular/forms';
import Yield from '@app/model/yield.model';
import {ScaleYieldFormChangedEvent} from '@app/view-recipe/types/scale-yield-form-changed-event';
import {ScaleYieldFormDto} from '@app/view-recipe/types/scale-yield-form-dto';
import {selectCurrentRouteRecipe} from '@app/store/selectors/app.selectors';
import {viewRecipePageActions} from '@app/store/actions/view-recipe-page.actions';
import {selectActiveRecipesScaleFactor} from '@app/store/selectors/view-recipe-page.selector';
import {ViewRecipeComponent} from '../view-recipe/view-recipe.component';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
    selector: 'app-view-recipe-container',
    templateUrl: './view-recipe-container.component.html',
    styleUrls: ['./view-recipe-container.component.css'],
    standalone: true,
    imports: [NgIf, ViewRecipeComponent, AsyncPipe]
})
export class ViewRecipeContainerComponent implements OnInit {
    private readonly store: Store<RecipeState>;

    private readonly sortService: SortService;

    private readonly scalingService: RecipeScalingService;

    private readonly formBuilder: FormBuilder;

    public recipe$: Observable<Recipe>;

    public scaleYieldFormDTOs: Observable<ScaleYieldFormDto[]>;

    @Output()
    public readonly yieldListItemChanged = new ReplaySubject<ScaleYieldFormChangedEvent>(1);

    constructor(store: Store<RecipeState>, sortService: SortService, scalingService: RecipeScalingService, formBuilder: FormBuilder) {
        this.store = store;
        this.sortService = sortService;
        this.scalingService = scalingService;
        this.formBuilder = formBuilder;
    }

    ngOnInit(): void {
        const originalRecipe$ = this.getRecipeByRouteParameter();
        this.recipe$ = this.scaleRecipe(originalRecipe$);
        this.scaleYieldFormDTOs = this.generateYieldForm(originalRecipe$, this.recipe$);
    }

    private getRecipeByRouteParameter(): Observable<Recipe> {
        return this.store.pipe(
          select(selectCurrentRouteRecipe),
          filter(recipe => recipe !== undefined),
          map(cloneDeep),
          map(recipe => this.sortService.sortRecipe(recipe)),
        )
    }

    private scaleRecipe(originalRecipe$: Observable<Recipe>): Observable<Recipe> {
        return originalRecipe$.pipe(
            combineLatestWith(this.store.select(selectActiveRecipesScaleFactor)),
            map(([originalRecipe, scaleFactor]) => this.scalingService.scale(
                originalRecipe,
                scaleFactor,
            )),
        );
    }

    public onYieldListItemChanged(event: ScaleYieldFormChangedEvent) {
        this.store.dispatch(viewRecipePageActions.activeRecipesYieldChanged({
            scaleFactor: event.quantity / event.originalQuantity,
        }));
    }

    private generateYieldForm(originalRecipe$: Observable<Recipe>, recipe$: Observable<Recipe>): Observable<ScaleYieldFormDto[]> {
        return originalRecipe$.pipe(
            combineLatestWith(recipe$),
            map(([originalRecipe, scaledRecipe]) =>
                originalRecipe.yield.map((originalYield) => ({
                    measuringUnit: originalYield.measuringUnit,
                    originalQuantity: originalYield.quantity,
                    formGroup: this.formBuilder.group({
                        quantity: this.getYieldByMeasuringUnitId(
                            scaledRecipe.yield,
                            originalYield.measuringUnit.id
                        ).quantity,
                    }),
                }))
            )
        );
    }

    private getYieldByMeasuringUnitId(yieldList: Yield[], measuringUnitId: number): Yield {
        return yieldList.find(yieldObject => yieldObject.measuringUnit.id === measuringUnitId);
    }
}
