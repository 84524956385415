<div>
  <h2 class="title">
    {{ recipe.title }}
    <small class="title-translation">
      {{ recipe.titleTranslation }}
    </small>
  </h2>

  <div class="infos">
    <div *ngIf="recipe.source" class="source">
      <a *ngIf="isSourceALink() else elseBlock" [href]="recipe.source" target="_blank">Quelle</a>
      <ng-template #elseBlock>
        <span>{{ recipe.source }}</span>
      </ng-template>
    </div>

    <div *ngIf="recipe.keywords.length > 0" class="keywords">
      <div *ngFor="let keyword of recipe.keywords" class="keyword">{{ keyword.name }}</div>
    </div>

    <div *ngIf="recipe.description" class="description">
      Beschreibung:
      <app-description [description]="recipe.description" />
    </div>

    <div *ngIf="recipe.cookingTime" class="cooking-time">
      Kochzeit:
      {{ recipe.cookingTime }}
    </div>

    <div *ngIf="recipe.preparationTime" class="preparation-time">
      Vorbereitungszeit:
      {{ recipe.preparationTime }}
    </div>
  </div>

  <div class="scale-yield-container">
    <app-scale-yield
      *ngFor="let yieldFormDTO of scaleYieldFormDTOs;"
      [scaleYieldFormDTO]="yieldFormDTO"
      (changed)="yieldListItemChanged.next($event)"
      class="scale-yield"
    ></app-scale-yield>
  </div>

  <div class="steps">
    <div class="step" *ngFor="let step of recipe.steps; let last = last">
      <div class="components">
        <table>
          <tr class="component" *ngFor="let component of step.components">
            <td class="ingredient">{{ component.ingredient.name }}</td>
            <td class="quantity">{{ component.quantity }}</td>
            <td class="measuring-unit">{{ component.measuringUnit.name }}</td>
            <td class="preparation">{{ component.preparation }}</td>
          </tr>
        </table>
      </div>
      <ol class="tasks">
        <li class="task" *ngFor="let task of step.tasks">
          {{ task }}
        </li>
      </ol>
      <hr class="step-separator" *ngIf="!last">
    </div>
  </div>

  <div class="timestamps">
    <div>Erstellt: {{ recipe.createdAt | date }}</div>
    <div>Geändert: {{ recipe.updatedAt | date }}</div>
  </div>
</div>

<div class="actions">
  <a class="button recipe-edit-button" [routerLink]="['/', PathNames.edit, recipe.id]">Bearbeiten</a>
</div>
