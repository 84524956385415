import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {merge, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {cloneDeep} from 'lodash-es';
import {selectRecipeById} from '@app/store/selectors/recipe.selector';
import {RecipeState} from '@app/store/reducers/recipe.reducer';
import {recipeActions} from '@app/store/actions/recipe.actions';
import {SortService} from '@app/services/sort/sort.service';
import {RecipeFormService} from '../services/recipe-form.service';
import Recipe from '@app/model/recipe.model';
import {DialogService} from '@app/services/dialog/dialog.service';
import {RecipeComponent} from '../recipe/recipe.component';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
    selector: 'app-edit-recipe-container',
    templateUrl: './recipe-container.component.html',
    styleUrls: ['./recipe-container.component.css'],
    standalone: true,
    imports: [NgIf, RecipeComponent, ReactiveFormsModule, AsyncPipe]
})
export class RecipeContainerComponent implements OnInit {
  private route: ActivatedRoute;

  private recipeFormService: RecipeFormService;

  public recipeFormGroup: Observable<UntypedFormGroup>;

  private readonly store: Store<RecipeState>;

  private readonly sortService: SortService;

  private readonly dialogService: DialogService;

  private isSuccessfulSaved = false;

  constructor(
    store: Store<RecipeState>,
    route: ActivatedRoute,
    recipeFormService: RecipeFormService,
    sortService: SortService,
    dialogService: DialogService
  ) {
    this.store = store;
    this.route = route;
    this.recipeFormService = recipeFormService;
    this.sortService = sortService;
    this.dialogService = dialogService;
  }

  ngOnInit(): void {
    this.getRecipe();
  }

  private getRecipe(): void {
    // if there is the id parameter, get the recipe with the matching id
    const editFormGroup = this.route.paramMap
      .pipe(
        filter(parameters => parameters.has('id')),
        map(parameters => +parameters.get('id')),
        switchMap(recipeId => this.store.pipe(
            select(selectRecipeById(recipeId)),
          )
        ),
        filter(value => value !== undefined),
        map(cloneDeep),
        map(recipe => this.sortService.sortRecipe(recipe)),
        map(recipe => this.recipeFormService.init(recipe))
      );

    // if there is no id parameter, create a new form group
    const createFormGroup = this.route.paramMap
      .pipe(
        filter(parameters => !parameters.has('id')),
        map(() => new Recipe()),
        map(recipe => this.recipeFormService.init(recipe))
      );

    this.recipeFormGroup = merge(
      editFormGroup,
      createFormGroup
    );
  }

  public saveRecipe(formGroup: UntypedFormGroup): void {
    const recipe = this.recipeFormService.createRecipe(formGroup.value);

    this.store.dispatch(recipeActions.addRecipe({
      data: recipe
    }));

    this.isSuccessfulSaved = true;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (this.isSuccessfulSaved) {
      return true;
    }

    return this.dialogService.confirm('Bist du sicher das du die Seite ohne speichern verlassen möchtest?');
  }
}
