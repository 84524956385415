import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {TasksComponent} from '../tasks/tasks.component';
import {ComponentListComponent} from '../component-list/component-list.component';

@Component({
    selector: 'app-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.css'],
    standalone: true,
    imports: [ComponentListComponent, TasksComponent]
})
export class StepComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  @Input({ required: true })
  set number(number: number) {
    this.formGroup
      .get('number')
      .setValue(number);
  }

  @Output()
  markedDirty = new EventEmitter<void>();

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe(() => {
        this.markedDirty.emit();
      });
  }

  get componentForms(): UntypedFormArray {
    return this.formGroup.get('components') as UntypedFormArray;
  }

  get taskForm(): UntypedFormControl {
    return this.formGroup.get('tasks') as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
