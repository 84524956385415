import {createActionGroup, emptyProps, props} from '@ngrx/store';
import MeasuringUnit from '@app/model/measuring-unit.model';

export const measuringUnitActions = createActionGroup({
    source: 'MeasuringUnit',
    events: {
        'Load All MeasuringUnits': emptyProps(),
        'Load All MeasuringUnits Success': props<{ data: MeasuringUnit[] }>(),
        'Load All MeasuringUnits Failure': props<{ error: any }>(),
        'Add MeasuringUnit': props<{ data: MeasuringUnit }>(),
        'Add MeasuringUnit Success': props<{ data: MeasuringUnit }>(),
        'Add MeasuringUnit Failure': props<{ error: any }>()
    }
});
