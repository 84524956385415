import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@Component({
    selector: 'app-save-component-property-button',
    templateUrl: './save-component-property-button.component.html',
    styleUrls: ['./save-component-property-button.component.css'],
    standalone: true,
    imports: [MatIconModule, AsyncPipe]
})
export class SaveComponentPropertyButtonComponent implements OnInit {
  @Input({ required: true })
  public nameControl: UntypedFormControl;

  @Input({ required: true })
  public propertyNames$: Observable<string[]>;

  @Output()
  public saveProperty = new EventEmitter<string>();

  public isButtonDisabled$: Observable<boolean>;

  ngOnInit(): void {
    const inputValueAndProperties$ = combineLatest([
      this.nameControl.valueChanges as Observable<string>,
      this.propertyNames$
    ]);

    this.isButtonDisabled$ = this.initIsButtonDisabled(inputValueAndProperties$, this.nameControl);
  }

  private initIsButtonDisabled(
    inputValueAndProperties$: Observable<[string, string[]]>,
    formControl: UntypedFormControl
  ): Observable<boolean> {
    return inputValueAndProperties$.pipe(
      map(([value, properties]) => ({value: value.toLowerCase(), properties})),
      map(dto =>
        !(
          formControl.dirty &&
          formControl.valid &&
          !dto.properties.some(property => property.toLowerCase() === dto.value)
        )
      ),
      startWith(true)
    );
  }

  public onClicked(): void {
    this.saveProperty.emit(this.nameControl.value);
  }
}
