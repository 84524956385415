import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseFormService} from './base-form-service';
import {MeasuringUnitFormService} from './measuring-unit-form.service';
import Yield from '@app/model/yield.model';

@Injectable({
  providedIn: 'root'
})
export class YieldFormService implements BaseFormService {
  private readonly builder: UntypedFormBuilder;
  private readonly measuringUnitFormService: MeasuringUnitFormService;

  constructor(
    builder: UntypedFormBuilder,
    measuringUnitFormService: MeasuringUnitFormService,
  ) {
    this.builder = builder;
    this.measuringUnitFormService = measuringUnitFormService;
  }

  public init(yields: Yield[]): UntypedFormGroup[] {
    return yields.map(yieldObject => {
      const group = this.createGroup();
      group.patchValue({
        id: yieldObject.id,
        quantity: yieldObject.quantity,
      });

      const unit = this.measuringUnitFormService.init(yieldObject.measuringUnit);
      group.setControl('measuringUnit', unit);

      return group;
    });
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      quantity: ['', Validators.required],
      measuringUnit: this.measuringUnitFormService.createGroup(),
    });
  }

  public createYield(formGroupValues): Yield {
    let quantity: number;
    if (typeof formGroupValues.quantity === 'string') {
      quantity = Number.parseFloat(
        formGroupValues.quantity.replace(',', '.')
      );
    } else {
      quantity = Number.parseFloat(formGroupValues.quantity);
    }

    return Yield.fromYieldInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      quantity,
      measuringUnit: this.measuringUnitFormService.createMeasuringUnit(
        formGroupValues.measuringUnit
      ),
    });
  }

  public isEmpty(formGroupValues): boolean {
    return formGroupValues.quantity === '' &&
      this.measuringUnitFormService.isEmpty(formGroupValues.measuringUnit)
    ;
  }
}
