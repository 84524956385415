import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {MeasuringUnitState} from '@app/store/reducers/measuring-unit.reducer';
import MeasuringUnit from '@app/model/measuring-unit.model';
import {measuringUnitActions} from '@app/store/actions/measuring-unit.actions';
import {selectAllMeasuringUnits} from '@app/store/selectors/measuring-unit.selector';
import {ComponentPropertyComponent} from '../component-property/component-property.component';

@Component({
    selector: 'app-measuring-unit',
    templateUrl: './measuring-unit.component.html',
    styleUrls: ['./measuring-unit.component.css'],
    standalone: true,
    imports: [ComponentPropertyComponent, ReactiveFormsModule]
})
export class MeasuringUnitComponent implements OnInit {
  private readonly store: Store<MeasuringUnitState>;

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  public measuringUnits$: Observable<MeasuringUnit[]>;

  constructor(store: Store<MeasuringUnitState>) {
    this.store = store;
  }

  ngOnInit(): void {
    this.measuringUnits$ = this.store.pipe(
      select(selectAllMeasuringUnits)
    );
  }

  public saveMeasuringUnit(measuringUnitName: string): void {
    const unit = MeasuringUnit.fromMeasuringUnitInterface({ name: measuringUnitName });

    this.store.dispatch(measuringUnitActions.addMeasuringUnit({
      data: unit
    }));
  }
}
