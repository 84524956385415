import {Component, Input} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {NgFor} from '@angular/common';

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.css'],
    standalone: true,
    imports: [NgFor, ReactiveFormsModule]
})
export class TasksComponent {
  @Input({ required: true })
  public control: UntypedFormControl;

  public controlNumberLines(): number {
    const controlValue = this.control.value as string;
    return controlValue.split('\n').length;
  }

  public arrayOfSize(length: number): undefined[] {
    return Array.from({length});
  }
}
