import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {recipeActions} from '@app/store/actions/recipe.actions';
import {ingredientActions} from '@app/store/actions/ingredient.actions';
import {keywordActions} from '@app/store/actions/keyword.actions';
import {measuringUnitActions} from '@app/store/actions/measuring-unit.actions';
import {appActions} from '@app/store/actions/app.actions';

@Injectable()
export class AppEffects {
  showInfoOnDataServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          recipeActions.addRecipeFailure,
          recipeActions.loadAllRecipesFailure,
          ingredientActions.addIngredientFailure,
          ingredientActions.loadAllIngredientsFailure,
          keywordActions.loadAllKeywordsFailure,
          measuringUnitActions.addMeasuringUnitFailure,
          measuringUnitActions.loadAllMeasuringUnitsFailure,
        ),
        map(action => action.error),
        tap(error =>
          this.snackBar.open(error, null, {
            duration: 5 * 1000,
          })
        )
      ),
    { dispatch: false }
  );

  loadKeywordData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => keywordActions.loadAllKeywords()),
    ),
  );

  loadIngredientData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => ingredientActions.loadAllIngredients()),
    ),
  );

  loadMeasuringUnitData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => measuringUnitActions.loadAllMeasuringUnits()),
    ),
  );

  loadRecipeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => recipeActions.loadAllRecipes()),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
  ) {}
}
