import Component, {ComponentInterface} from './component.model';
import Ingredient from '@app/model/ingredient.model';

export interface StepInterface {
  id?: number | null;
  number: number;
  tasks: string[];
  components: ComponentInterface[];
}

export default class Step implements StepInterface {
  public id: number | null;
  public number: number;
  public tasks: string[];
  public components: Component[];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static fromStepInterface(stepInterface: StepInterface): Step {
    const step = new Step();
    step.id = stepInterface.id || null;
    step.number = stepInterface.number;
    step.tasks = stepInterface.tasks;
    step.components = stepInterface.components.map(component => Component.fromComponentInterface(component));

    return step;
  }

  public getIngredients(): Ingredient[] {
    return this.components.map(component => component.getIngredient());
  }
}
