import {Component, Input} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {MeasuringUnitComponent} from '../measuring-unit/measuring-unit.component';

@Component({
    selector: 'app-yield',
    templateUrl: './yield.component.html',
    styleUrls: ['./yield.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, MeasuringUnitComponent]
})
export class YieldComponent {
  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  get measuringUnitForm(): UntypedFormGroup {
    return this.formGroup.get('measuringUnit') as UntypedFormGroup;
  }
}
