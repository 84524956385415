import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Component from '@app/model/component.model';
import {BaseFormService} from './base-form-service';
import {IngredientFormService} from './ingredient-form.service';
import {MeasuringUnitFormService} from './measuring-unit-form.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentFormService implements BaseFormService {
  private readonly builder: UntypedFormBuilder;
  private readonly ingredientFormService: IngredientFormService;
  private readonly measuringUnitFormService: MeasuringUnitFormService;

  constructor(
    builder: UntypedFormBuilder,
    ingredientFormService: IngredientFormService,
    measuringUnitFormService: MeasuringUnitFormService,
  ) {
    this.builder = builder;
    this.ingredientFormService = ingredientFormService;
    this.measuringUnitFormService = measuringUnitFormService;
  }

  public init(components: Component[]): UntypedFormGroup[] {
    return components.map(component => {
      const group = this.createGroup();
      group.patchValue({
        id: component.id,
        quantity: component.quantity,
        preparation: component.preparation || '',
      });

      const unit = this.measuringUnitFormService.init(component.measuringUnit);
      group.setControl('measuringUnit', unit);

      const ingredient = this.ingredientFormService.init(component.ingredient);
      group.setControl('ingredient', ingredient);

      return group;
    });
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      quantity: ['', Validators.required],
      measuringUnit: this.measuringUnitFormService.createGroup(),
      ingredient: this.ingredientFormService.createGroup(),
      preparation: ['', Validators.maxLength(45)],
    });
  }

  public createComponent(formGroupValues): Component {
    let quantity: number;
    if (typeof formGroupValues.quantity === 'string') {
      quantity = Number.parseFloat(
        formGroupValues.quantity.replace(',', '.')
      );
    } else {
      quantity = Number.parseFloat(formGroupValues.quantity);
    }

    return Component.fromComponentInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      quantity,
      ingredient: this.ingredientFormService.createIngredient(
        formGroupValues.ingredient
      ),
      measuringUnit: this.measuringUnitFormService.createMeasuringUnit(
        formGroupValues.measuringUnit
      ),
      preparation: formGroupValues.preparation,
    });
  }

  public isEmpty(formGroupValues): boolean {
    return formGroupValues.quantity === '' &&
      formGroupValues.preparation === '' &&
      this.measuringUnitFormService.isEmpty(formGroupValues.measuringUnit) &&
      this.ingredientFormService.isEmpty(formGroupValues.ingredient)
    ;
  }
}
