import {createSelector} from '@ngrx/store';
import {listRecipesPageFeature} from '../reducers/list-recipes-page.reducer';

export const selectSearchString = listRecipesPageFeature.selectSearchString;

export const selectFilterKeywords = createSelector(
  listRecipesPageFeature.selectFilterKeywords,
  keywords => [...keywords],
);

export const selectScrollPosition = listRecipesPageFeature.selectScrollPosition;
