import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {viewRecipePageActions} from '@app/store/actions/view-recipe-page.actions';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {selectRouteId} from '@app/store/selectors/router.selector';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {RouterState} from '@ngrx/router-store';

@Injectable()
export class ViewEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RouterState>,
    private router: Router
  ) {}

  navigateToListOnRemovingCurrentRecipeFromNavigation$ = createEffect(() => this.actions$.pipe(
      ofType(viewRecipePageActions.recipeViewPageClosed),
      withLatestFrom(this.store.select(selectRouteId as any)),
      map(([action, routeId]) => [action.recipeId, Number(routeId)]),
      filter(([recipeId, routeId]) => recipeId === routeId),
      tap(() => this.router.navigate(['/'])),
    ),
    {dispatch: false}
  );

  mapActiveRecipeYieldChangedToYieldChangedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewRecipePageActions.activeRecipesYieldChanged),
      withLatestFrom(this.store.pipe(
        select(selectRouteId as any),
        map(Number),
      )),
      map(([action, recipeId]) =>
          viewRecipePageActions.recipesYieldChanged({
          scaleFactor: action.scaleFactor,
          recipeId,
        })
      )
    )
  );
}
