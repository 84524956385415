import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {ROUTER_NAVIGATED, RouterState} from '@ngrx/router-store';
import {viewRecipePageActions} from '../actions/view-recipe-page.actions';
import {select, Store} from '@ngrx/store';
import {routerSelectors, selectRouteId,} from '@app/store/selectors/router.selector';
import {PathNames} from '@app/app.routes';
import {ScreenWakeLockService} from '@app/services/screen-wake-lock/screen-wake-lock.service';

@Injectable()
export class RouterEffects {
  dispatchRecipeViewedEvent$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(() => this.store.select(selectRouteId as any)),
      map(Number),
      filter(id => !Number.isNaN(id)),
      map(id => viewRecipePageActions.recipeViewed({ recipeId: id, viewedAt: new Date() }))
    ),
  );

  requestWakeLock$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(() => this.store.pipe(
        select(routerSelectors.selectCurrentRoute as any),
        first(),
      )),
      map((currentRoute) => currentRoute?.url[0].path),
      filter((path) => path === PathNames.view),
      tap(() => this.screenWakeLockService.request()),
    ),
    { dispatch: false },
  );

  releaseWakeLock$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(() => this.store.pipe(
        select(routerSelectors.selectCurrentRoute as any),
        first(),
      )),
      map((currentRoute) => currentRoute?.url[0].path),
      filter((path) => path !== PathNames.view),
      tap(() => this.screenWakeLockService.release()),
    ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<RouterState>,
    private screenWakeLockService: ScreenWakeLockService,
  ) {}
}
