import {Component, Input, OnInit} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import Ingredient from '@app/model/ingredient.model';
import {ingredientActions} from '@app/store/actions/ingredient.actions';
import {select, Store} from '@ngrx/store';
import {IngredientState} from '@app/store/reducers/ingredient.reducer';
import {selectAllIngredients} from '@app/store/selectors/ingredient.selector';
import {Observable} from 'rxjs';
import {ComponentPropertyComponent} from '../component-property/component-property.component';

@Component({
    selector: 'app-ingredient',
    templateUrl: './ingredient.component.html',
    styleUrls: ['./ingredient.component.css'],
    standalone: true,
    imports: [ComponentPropertyComponent, ReactiveFormsModule]
})
export class IngredientComponent implements OnInit {
  private readonly store: Store<IngredientState>;

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  public ingredients$: Observable<Ingredient[]>;

  constructor(store: Store<IngredientState>) {
    this.store = store;
  }

  ngOnInit(): void {
    this.ingredients$ = this.store.pipe(
      select(selectAllIngredients)
    );
  }

  public saveIngredient(ingredientName: string): void {
    const ingredient = Ingredient.fromIngredientInterface({
      name: ingredientName,
    });

    this.store.dispatch(ingredientActions.addIngredient({
      data: ingredient
    }));
  }
}
