import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED, ROUTER_REQUEST, RouterState} from '@ngrx/router-store';
import {delay, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {PathNames} from '@app/app.routes';
import {routerSelectors} from '@app/store/selectors/router.selector';
import {listRecipesPageActions} from '@app/store/actions/list-recipes-page.actions';
import {viewRecipePageActions} from '@app/store/actions/view-recipe-page.actions';
import * as listRecipesPageSelectors from '@app/store/selectors/list-recipes-view.selectors';
import * as viewRecipePageSelectors from '@app/store/selectors/view-recipe-page.selector';
import {ViewportScroller} from '@angular/common';

@Injectable()
export class NativeEffects {
  restoreListRecipesPageScrollPosition$ = createEffect(() =>
      this.actions$.pipe(
        ofType(listRecipesPageActions.pageInitialised),
        switchMap(() => this.store.pipe(
          select(listRecipesPageSelectors.selectScrollPosition as any),
          first(),
        )),
        tap(scrollPosition => this.scroller.scrollToPosition([0, scrollPosition])),
      ),
    { dispatch: false }
  );

  restoreViewRecipePageScrollPosition$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        switchMap(() => this.store.pipe(
          select(routerSelectors.selectCurrentRoute as any),
          first(),
        )),
        filter(currentRoute => currentRoute?.url[0].path === PathNames.view),
        switchMap(() => this.store.pipe(
          select(viewRecipePageSelectors.selectActiveRecipesScrollPosition as any),
          first(),
        )),
        delay(10), // wait for angular to load the recipe. can't scroll much otherwise
        tap(scrollPosition => this.scroller.scrollToPosition([0, scrollPosition])),
      ),
    { dispatch: false }
  );

  dispatchCurrentScrollPositionOnRouterRequestFromListPage = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      switchMap(() => this.store.pipe(
        select(routerSelectors.selectCurrentRoute as any),
        first(),
      )),
      filter(currentRoute => currentRoute?.url[0].path === PathNames.list),
      map(() => this.scroller.getScrollPosition()[1]),
      map(scrollPosition => listRecipesPageActions.recipesScrollPositionChanged({ scrollPosition })),
    ),
  );

  dispatchCurrentScrollPositionOnRouterRequestFromViewPage = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      switchMap(() => this.store.pipe(
        select(routerSelectors.selectCurrentRoute as any),
        first(),
      )),
      filter(currentRoute => currentRoute?.url[0].path === PathNames.view),
      map(currentRoute => {
        const scrollPosition = this.scroller.getScrollPosition()[1];

        return viewRecipePageActions.recipesScrollPositionChanged({
          scrollPosition,
          recipeId: Number(currentRoute.url[1].path),
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RouterState>,
    private scroller: ViewportScroller,
  ) {}
}
