import ComponentProperty from '@app/model/component-property.model';

export interface KeywordInterface {
  id?: number | null;
  name: string;
}

export default class Keyword implements KeywordInterface, ComponentProperty {
  public id: number | null;
  public name: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static fromKeywordInterface(keywordInterface: KeywordInterface): Keyword {
    const keyword = new Keyword();

    keyword.id = keywordInterface.id;
    keyword.name = keywordInterface.name;

    return keyword;
  }
}
