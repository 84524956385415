import {Component, OnInit} from '@angular/core';
import {ComponentFormService} from '../services/component-form.service';
import {AbstractFormListComponent} from '../abstract-form-list/abstract-form-list.component';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {ComponentComponent} from '../component/component.component';
import {NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'app-component-list',
    templateUrl: './component-list.component.html',
    styleUrls: ['./component-list.component.css'],
    standalone: true,
    imports: [NgFor, ComponentComponent, ReactiveFormsModule, NgIf, MatIconModule]
})
export class ComponentListComponent extends AbstractFormListComponent implements OnInit {

  constructor(formService: ComponentFormService ) {
    super(formService);
  }

  ngOnInit(): void {
    // this fixes NG0100: ExpressionChangedAfterItHasBeenCheckedError (https://blog.angular-university.io/angular-debugging/)
    setTimeout(() => {
      this.add(false);
    });
  }

  addOnLastComponentDirty(isLast: boolean): void {
    if (isLast) {
      this.add();
    }
  }
}
