import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const listRecipesPageActions = createActionGroup({
  source: "ListRecipesPage",
  events: {
    "Search string changed": props<{ searchString: string }>(),
    "Filter keyword added": props<{ keywordName: string }>(),
    "Filter keyword removed": props<{ keywordName: string }>(),
    "Recipes scroll position changed": props<{ scrollPosition: number }>(),
    "Page initialised": emptyProps(),
  },
});
