import {createSelector} from '@ngrx/store';
import {viewRecipePageFeature} from '@app/store/reducers/view-recipe-page.reducer';
import {selectRouteId} from '@app/store/selectors/router.selector';

export const selectAllViewedRecipeIds = createSelector(
  viewRecipePageFeature.selectOpenedRecipes,
  openedRecipes =>
    openedRecipes
      .toSorted(
        (a, b) => a.firstTimeViewedAt.valueOf() - b.firstTimeViewedAt.valueOf(),
      )
      .map((recipe) => recipe.recipeId),
);

export const selectActiveRecipesScaleFactor = createSelector(
  viewRecipePageFeature.selectOpenedRecipes,
    selectRouteId,
  (openedRecipes, recipeId) => {
    const activeRecipe = openedRecipes.find(recipe => recipe.recipeId === +recipeId)
    return activeRecipe?.scaleFactor || 1;
  },
);

export const selectActiveRecipesScrollPosition = createSelector(
  viewRecipePageFeature.selectOpenedRecipes,
  selectRouteId,
  (openedRecipes, recipeId) => {
    const activeRecipe = openedRecipes.find(recipe => recipe.recipeId === +recipeId)
    return activeRecipe?.scrollPosition || 0;
  },
);
