import {createSelector} from '@ngrx/store';
import {selectAllKeywordNames} from '@app/store/selectors/keyword.selector';
import {selectFilterKeywords} from '@app/store/selectors/list-recipes-view.selectors';
import {selectAllRecipesSorted, selectRecipeEntities} from '@app/store/selectors/recipe.selector';
import {selectRouteId} from '@app/store/selectors/router.selector';

export const selectAllKeywordsWithoutSelectedFilterKeywords = createSelector(
  selectAllKeywordNames,
  selectFilterKeywords,
  (allKeywords, selectedKeywords) => allKeywords.filter(keyword => !selectedKeywords.includes(keyword))
);

export const selectRecipesFilteredByKeywords = createSelector(
  selectAllRecipesSorted,
  selectFilterKeywords,
  (recipes, selectedKeywordsNames) => recipes.filter(recipe => {
    const recipesKeywordsNames = recipe.keywords.map(keyword => keyword.name);
    return selectedKeywordsNames.every(selectedKeywordName => recipesKeywordsNames.includes(selectedKeywordName));
  }),
);

export const selectCurrentRouteRecipe = createSelector(
  selectRecipeEntities,
  selectRouteId,
  (recipes, id) => recipes[id]
);
