<mat-chip-grid #chipList aria-label="keyword selection">
  <mat-basic-chip
    *ngFor="let keywordName of selectedKeywordNames"
    (removed)="remove(keywordName)"
    disableRipple
  >
    {{keywordName}}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-basic-chip>
</mat-chip-grid>

<input
  placeholder="Keyword ..."
  class="form-field keyword-input"
  #keywordNameInput
  [formControl]="keywordNameCtrl"
  [matAutocomplete]="auto"
  [matChipInputFor]="chipList"
  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="add($event)"
>

<mat-autocomplete
  [autoActiveFirstOption]="!allowAddingKeywords"
  #auto="matAutocomplete"
  (optionSelected)="selected($event)"
>
  <mat-option
    *ngFor="let keywordName of filteredKeywordNames | async"
    [value]="keywordName"
    class="keyword-option"
  >
    {{keywordName}}
  </mat-option>
</mat-autocomplete>

