<div class="line-numbers">
  <div *ngFor="let _ of arrayOfSize(controlNumberLines()); let i = index;">
    {{ i + 1 }}.
  </div>
</div>
<textarea
  class="tasks-textarea"
  cdkTextareaAutosize
  cdkAutosizeMinRows="3"
  title="tasks"
  [formControl]="control"
  placeholder="Verarbeitungsschritte"
  wrap="off"
></textarea>
