import {Injectable} from '@angular/core';
import Recipe, {RecipeInterface} from '@app/model/recipe.model';
import Step, {StepInterface} from '@app/model/step.model';
import Component, {ComponentInterface} from '@app/model/component.model';
import Yield, {YieldInterface} from '@app/model/yield.model';
import RecipeRequestInterface, {
  ComponentRequestInterface,
  StepRequestInterface,
  YieldRequestInterface
} from '@app/services/api/recipe-request-interface';
import {MeasuringUnitMapperService} from '@app/services/mapper/measuring-unit-mapper.service';
import {IngredientMapperService} from '@app/services/mapper/ingredient-mapper.service';
import {KeywordMapperService} from '@app/services/mapper/keyword-mapper.service';

@Injectable({
  providedIn: "root",
})
export class RecipeMapperService {
  private readonly measuringUnitMapper: MeasuringUnitMapperService;
  private readonly ingredientMapper: IngredientMapperService;
  private readonly keywordMapper: KeywordMapperService;

  constructor(
    measuringUnitMapper: MeasuringUnitMapperService,
    ingredientMapperService: IngredientMapperService,
    keywordMapperService: KeywordMapperService,
  ) {
    this.measuringUnitMapper = measuringUnitMapper;
    this.ingredientMapper = ingredientMapperService;
    this.keywordMapper = keywordMapperService;
  }

  private static stripEmptyIdFromRecipe(recipe: RecipeRequestInterface): RecipeRequestInterface {
    const steps = recipe.steps.map((step) =>
      RecipeMapperService.stripEmptyIdFromStep(step)
    );
    const yields = recipe.yield.map((yieldObject) =>
      RecipeMapperService.stripEmptyIdFromYield(yieldObject)
    );

    const o = {
      ...recipe,
      steps,
      yield: yields,
    };

    if (o.id === null) {
      delete o.id;
    }

    return o;
  }

  private static stripEmptyIdFromStep(step: StepRequestInterface): StepRequestInterface {
    const components = step.components.map((component) =>
      RecipeMapperService.stripEmptyIdFromComponent(component)
    );

    const o = {
      ...step,
      components,
    };

    if (o.id === null) {
      delete o.id;
    }

    return o;
  }

  private static stripEmptyIdFromComponent(component: ComponentRequestInterface): ComponentRequestInterface {
    const measuringUnit = { ...component.measuring_unit };
    const ingredient = { ...component.ingredient };
    const o = {
      ...component,
      measuring_unit: measuringUnit,
      ingredient,
    };
    if (o.id === null) {
      delete o.id;
    }

    return o;
  }

  private static stripEmptyIdFromYield(yieldObject: YieldRequestInterface): YieldRequestInterface {
    const measuringUnit = { ...yieldObject.measuring_unit };
    const o = {
      ...yieldObject,
      measuring_unit: measuringUnit,
    };
    if (o.id === null) {
      delete o.id;
    }

    return o;
  }

  public mapModelToRequest(recipe: Recipe): RecipeRequestInterface {
    const request = this.mapModelToRecipeRequest(recipe);

    return  RecipeMapperService.stripEmptyIdFromRecipe(request);
  }

  private mapModelToRecipeRequest(recipe: Recipe): RecipeRequestInterface {
    return {
      cooking_time: recipe.cookingTime,
      created_at: recipe.createdAt,
      description: recipe.description,
      id: recipe.id,
      keywords: recipe.keywords.map(keyword => this.keywordMapper.mapModelToRequest(keyword)),
      preparation_time: recipe.preparationTime,
      source: recipe.source,
      steps: recipe.steps.map(step => this.mapModelToStepRequest(step)),
      title: recipe.title,
      title_translation: recipe.titleTranslation,
      updated_at: recipe.updatedAt,
      yield: recipe.yield.map(yieldObject => this.mapModelToYieldRequest(yieldObject)),
    }
  }

  private mapModelToStepRequest(step: Step): StepRequestInterface {
    return {
      components: step.components.map(component => this.mapModelToComponentRequest(component)),
      id: step.id,
      number: step.number,
      tasks: step.tasks,
    };
  }

  private mapModelToComponentRequest(component: Component): ComponentRequestInterface {
    return {
      id: component.id,
      ingredient: this.ingredientMapper.mapModelToRequest(component.ingredient),
      measuring_unit: this.measuringUnitMapper.mapModelToRequest(component.measuringUnit),
      preparation: component.preparation,
      quantity: component.quantity
    };
  }

  private mapModelToYieldRequest(yieldObject: Yield): YieldRequestInterface {
    return {
      id: yieldObject.id,
      measuring_unit: this.measuringUnitMapper.mapModelToRequest(yieldObject.measuringUnit),
      quantity: yieldObject.quantity
    };
  }


  public mapRequestToModel(recipeRequest: RecipeRequestInterface): Recipe {
    return Recipe.fromRecipeInterface(
      this.mapRequestToRecipe(recipeRequest)
    );
  }

  private mapRequestToRecipe(
    recipeRequest: RecipeRequestInterface
  ): RecipeInterface {
    return {
      cookingTime: recipeRequest.cooking_time,
      createdAt: recipeRequest.created_at,
      description: recipeRequest.description,
      id: recipeRequest.id,
      keywords: recipeRequest.keywords.map(keyword =>
        this.keywordMapper.mapRequestToModel(keyword)
      ),
      preparationTime: recipeRequest.preparation_time,
      source: recipeRequest.source,
      steps: recipeRequest.steps.map(step =>
        this.mapRequestToStep(step)
      ),
      title: recipeRequest.title,
      titleTranslation: recipeRequest.title_translation,
      updatedAt: recipeRequest.updated_at,
      yield: recipeRequest.yield.map(yieldObject=>
        this.mapRequestToYield(yieldObject)
      ),
    };
  }

  private mapRequestToStep(
    stepRequest: StepRequestInterface
  ): StepInterface {
    return {
      id: stepRequest.id,
      components: stepRequest.components.map((component) =>
        this.mapRequestToComponent(component)
      ),
      number: stepRequest.number,
      tasks: stepRequest.tasks,
    };
  }

  private mapRequestToComponent(
    componentRequest: ComponentRequestInterface
  ): ComponentInterface {
    return {
      id: componentRequest.id,
      quantity: componentRequest.quantity,
      preparation: componentRequest.preparation,
      ingredient: this.ingredientMapper.mapRequestToModel(componentRequest.ingredient),
      measuringUnit: this.measuringUnitMapper.mapRequestToModel(componentRequest.measuring_unit),
    };
  }

  private mapRequestToYield(request: YieldRequestInterface ): YieldInterface {
    return {
      id: request.id,
      quantity: request.quantity,
      measuringUnit: this.measuringUnitMapper.mapRequestToModel(request.measuring_unit),
    };
  }
}
