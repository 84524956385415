import {createFeature, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ingredientActions} from '../actions/ingredient.actions';
import Ingredient from '@app/model/ingredient.model';

export const ingredientFeatureKey = "ingredients";

export type IngredientState = EntityState<Ingredient>

const adapter: EntityAdapter<Ingredient> = createEntityAdapter<Ingredient>();

export const initialState: IngredientState = adapter.getInitialState({});

const reducer = createReducer(
  initialState,
  on(
    ingredientActions.loadAllIngredients,
    ingredientActions.loadAllIngredientsFailure,
    ingredientActions.addIngredient,
    ingredientActions.addIngredientFailure,
    (state) => state,
  ),
  on(ingredientActions.loadAllIngredientsSuccess, (state, action) =>
    adapter.setAll(action.data, state),
  ),
  on(ingredientActions.addIngredientSuccess, (state, { data: ingredient }) =>
    adapter.addOne(ingredient, state),
  ),
);

export const ingredientFeature = createFeature({
  name: ingredientFeatureKey,
  reducer,
});

export const { selectAll } = adapter.getSelectors();
