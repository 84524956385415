<app-select-component-property
  [formGroup]="formGroup"
  [properties$]="properties$"
></app-select-component-property>

<app-save-component-property-button
  [nameControl]="nameFormControl"
  [propertyNames$]="propertyNames$"
  (saveProperty)="onSaveProperty($event)"
></app-save-component-property-button>
