import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Keyword from '@app/model/keyword.model';

@Injectable({
  providedIn: 'root'
})
export class KeywordFormService {
  private builder: UntypedFormBuilder;

  constructor(builder: UntypedFormBuilder) {
    this.builder = builder;
  }

  public init(keywords: Keyword[]): UntypedFormGroup[] {
    return keywords.map(keyword => {
      const group = this.createGroup();
      group.patchValue({
        id: keyword.id,
        name: keyword.name,
      });

      return group;
    });
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      name: ['', [ Validators.required, Validators.maxLength(127) ]],
    });
  }

  public createKeyword(formGroupValues): Keyword {
    return  Keyword.fromKeywordInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      name: formGroupValues.name,
    });
  }

  public isEmpty(formGroupValues): boolean {
    return formGroupValues.name === '';
  }
}
