import {Component, OnInit} from '@angular/core';
import {RecipeState as RecipeState} from './store/reducers/recipe.reducer';
import {Store} from '@ngrx/store';
import {appActions} from '@app/store/actions/app.actions';
import {RouterOutlet} from '@angular/router';
import {NavigationComponent} from './navigation/navigation.component';
import {DevelopmentBarComponent} from '@app/dev-bar/development-bar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NavigationComponent, RouterOutlet, DevelopmentBarComponent]
})
export class AppComponent implements OnInit {
  private readonly store: Store<RecipeState>;

  constructor(store: Store<RecipeState>) {
    this.store = store;
  }

  ngOnInit(): void {
    this.store.dispatch(appActions.appInitialised());
  }
}
