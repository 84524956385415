import {Injectable} from '@angular/core';
import Recipe from '@app/model/recipe.model';
import {cloneDeep} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class RecipeScalingService {
  static readonly maxNumberDecimalPlaces : number = 3;

  public scale(recipe: Recipe, factor: number): Recipe {
    const clonedRecipe = cloneDeep(recipe);

    if (factor < 0 ) {
      factor = 0;
    }

    for (const yieldObject of clonedRecipe.yield) {
      yieldObject.quantity = RecipeScalingService.scaleQuantity(
          yieldObject.quantity, factor
      );
    }

    for (const step of clonedRecipe.steps) {
      for (const component of step.components) {
        component.quantity = RecipeScalingService.scaleQuantity(
            component.quantity, factor
        );
      }
    }

    return clonedRecipe;
  }

  private static scaleQuantity(quantity: number, factor: number): number {
    return RecipeScalingService.round(
        quantity * factor,
        RecipeScalingService.maxNumberDecimalPlaces
    );
  }

  private static round(n: number, decimalPlaces: number): number {
    const multiplier = Math.pow(10, decimalPlaces);

    return Math.round((n + Number.EPSILON) * multiplier) / multiplier;
  }

}
