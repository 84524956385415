import Ingredient, {IngredientInterface} from './ingredient.model';
import MeasuringUnit, {MeasuringUnitInterface} from './measuring-unit.model';

export interface ComponentInterface {
  id?: number | null;
  quantity: number;
  preparation: string;
  ingredient: IngredientInterface;
  measuringUnit: MeasuringUnitInterface;
}

export default class Component implements ComponentInterface {
  public id: number | null;
  public quantity: number;
  public preparation: string;
  public ingredient: Ingredient;
  public measuringUnit: MeasuringUnit;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static fromComponentInterface(componentInterface: ComponentInterface): Component {
    const component = new Component();
    component.id = componentInterface.id || null;
    component.quantity = componentInterface.quantity;
    component.preparation = componentInterface.preparation;
    component.ingredient = Ingredient.fromIngredientInterface(componentInterface.ingredient);
    component.measuringUnit = MeasuringUnit.fromMeasuringUnitInterface(componentInterface.measuringUnit);

    return component;
  }

  public getIngredient(): Ingredient {
    return this.ingredient;
  }
}
