import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {AsyncPipe, NgFor} from '@angular/common';

@Component({
    selector: 'app-keywords',
    templateUrl: './keywords.component.html',
    styleUrls: ['./keywords.component.css'],
    standalone: true,
    imports: [NgFor, MatIconModule, ReactiveFormsModule, MatAutocompleteModule, MatOptionModule, AsyncPipe, MatChipsModule]
})
export class KeywordsComponent {
  // the keys to tell to select a keyword in the input
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public readonly keywordNameCtrl = new UntypedFormControl();
  // the keywords filtered by typing
  public readonly filteredKeywordNames: Observable<string[]>;

  @ViewChild('keywordNameInput') keywordNameInput: ElementRef<HTMLInputElement>;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Output()
  public readonly createKeyword = new EventEmitter<string>();

  @Output()
  public readonly selectKeyword = new EventEmitter<string>();

  @Output()
  public readonly removeKeyword = new EventEmitter<string>();

  @Input({ required: true })
  public allKeywordNames: string[] = [];

  @Input({ required: true })
  public selectedKeywordNames: string[] = [];

  @Input()
  public allowAddingKeywords = true;

  constructor() {
    this.filteredKeywordNames = this.keywordNameCtrl.valueChanges.pipe(
      startWith(null),
      map((keywordName: string | null) => (keywordName ? this.filter(keywordName) : [...this.allKeywordNames])),
      map(keywordNames => keywordNames.sort((a, b) => a > b ? 1 : -1)),
    );
  }

  add(event: MatChipInputEvent): void {
    if (!this.allowAddingKeywords) {
      return;
    }

    const value = (event.value || '').trim();

    if (value) {
      this.createKeyword.emit(value);
    }

    this.autocomplete.closePanel();
    // Clear the input value
    event.chipInput!.clear();
    this.keywordNameCtrl.setValue(null);
  }

  remove(keywordName: string) {
    this.removeKeyword.emit(keywordName);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;

    this.selectKeyword.emit(value);

    // reset input
    this.keywordNameInput.nativeElement.value = '';
    this.keywordNameCtrl.setValue(null);
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allKeywordNames.filter(keywordName => keywordName.toLowerCase().includes(filterValue));
  }
}
