import {Injectable} from '@angular/core';
import Recipe from '@app/model/recipe.model';

@Injectable({
  providedIn: 'root'
})
export class SortService {
  public sortRecipe(recipe: Recipe): Recipe {
    recipe.steps.sort((a, b) => a.number - b.number);

    recipe.yield = recipe.yield.sort((a, b) => a.measuringUnit.name.localeCompare(b.measuringUnit.name));

    recipe.steps = recipe.steps.map((step) => {
      step.components.sort((a, b) =>
        a.ingredient.name.localeCompare(b.ingredient.name)
      );
      return step;
    });

    return recipe;
  }
}
