import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatestWith, map, Observable} from 'rxjs';
import {RecipeState} from '@app/store/reducers/recipe.reducer';
import Recipe from '@app/model/recipe.model';
import {FilterService} from '@app/services/filter/filter.service';
import {selectFilterKeywords, selectSearchString} from '@app/store/selectors/list-recipes-view.selectors';
import {listRecipesPageActions} from '@app/store/actions/list-recipes-page.actions';
import {selectAllKeywordsWithoutSelectedFilterKeywords, selectRecipesFilteredByKeywords} from '@app/store/selectors/app.selectors';
import {AsyncPipe} from '@angular/common';
import {ListRecipesComponent} from '../list-recipes/list-recipes.component';

@Component({
    selector: 'app-list-recipes-container',
    templateUrl: './list-recipes-container.component.html',
    styleUrls: ['./list-recipes-container.component.css'],
    standalone: true,
    imports: [ListRecipesComponent, AsyncPipe]
})
export class ListRecipesContainerComponent implements OnInit {
  private readonly store: Store<RecipeState>;

  private readonly filterService: FilterService;

  public recipes$: Observable<Recipe[]>;

  public allKeywordNames$: Observable<string[]>;

  public selectedKeywordNames$: Observable<string[]>;

  public readonly searchString$: Observable<string>;

  constructor(store: Store<RecipeState>, filterService: FilterService) {
    this.store = store;
    this.filterService = filterService;

    this.searchString$ = store.select(selectSearchString);
    this.selectedKeywordNames$ = store.select(selectFilterKeywords);
  }

  searchTermChanged(searchString: string) {
    this.store.dispatch(listRecipesPageActions.searchStringChanged({ searchString }));
  }

  ngOnInit(): void {
    this.store.dispatch(listRecipesPageActions.pageInitialised());

    this.recipes$ = this.store.pipe(
      select(selectRecipesFilteredByKeywords),
      combineLatestWith(
        this.store.select(selectSearchString)
      ),
      map(([recipes, searchTerm]) => recipes.filter(recipe =>
        this.recipeIncludes(recipe, searchTerm)
      )),
    );

    this.allKeywordNames$ = this.store.pipe(
      select(selectAllKeywordsWithoutSelectedFilterKeywords),
    );
  }

  public keywordSelected(keywordName: string) {
    this.store.dispatch(listRecipesPageActions.filterKeywordAdded({ keywordName }));
  }

  public keywordUnselected(keywordName: string) {
    this.store.dispatch(listRecipesPageActions.filterKeywordRemoved({ keywordName }));
  }

  private recipeIncludes(recipe: Recipe, searchTerm: string): boolean {
    const ingredients = recipe.getIngredients();
    const ingredientsNames = ingredients.map(ingredient => ingredient.name);
    const set = new Set<string>(ingredientsNames)

    for (const item of set) {
      if (this.filterService.includesIgnoringDiacritics(item, searchTerm)) {
        return true;
      }
    }

    return this.filterService.includesIgnoringDiacritics(recipe.title, searchTerm) ||
      this.filterService.includesIgnoringDiacritics(recipe.titleTranslation ?? "", searchTerm);
  }
}
