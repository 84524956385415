import ComponentProperty from './component-property.model';

export interface MeasuringUnitInterface {
  id?: number | null;
  name: string;
}

export default class MeasuringUnit implements MeasuringUnitInterface, ComponentProperty {
  public id: number | null;
  public name: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static fromMeasuringUnitInterface(measuringUnitInterface: MeasuringUnitInterface): MeasuringUnit {
    const unit = new MeasuringUnit();
    unit.id = measuringUnitInterface.id || null;
    unit.name = measuringUnitInterface.name;

    return unit;
  }
}
