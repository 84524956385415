<div class="header-container">
  <h2 class="title">Rezepte</h2>
  <button class="create-recipe-button" [routerLink]="['/', PathNames.edit]">
    <mat-icon class="add-icon">add</mat-icon>
  </button>
</div>

<app-keywords
  class="keyword-filter-selection"
  [allKeywordNames]="allKeywordNames"
  [selectedKeywordNames]="selectedKeywordNames"
  [allowAddingKeywords]="false"
  (selectKeyword)="keywordSelected.next($event)"
  (removeKeyword)="keywordUnselected.next($event)"
/>

<input
  class="form-field search-recipe-input"
  type="text"
  title="Suchen"
  placeholder="Suchen"
  [value]="searchString"
  (input)="onSearchTermChanged($event)"
/>

<ul class="recipe-list">
  <li
    class="recipe-list-item"
    *ngFor="let recipe of recipes; trackBy:getRecipeId"
  >
    <a
      class="recipe-list-item-link"
      [routerLink]="['/', PathNames.view, recipe.id]"
    >
      {{recipe.title}}<span *ngIf="recipe.titleTranslation"> &ndash; {{recipe.titleTranslation}}</span>
    </a>
  </li>
</ul>
