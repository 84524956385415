import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IngredientComponent} from '../ingredient/ingredient.component';
import {MeasuringUnitComponent} from '../measuring-unit/measuring-unit.component';

@Component({
    selector: 'app-component',
    templateUrl: './component.component.html',
    styleUrls: ['./component.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, MeasuringUnitComponent, IngredientComponent]
})
export class ComponentComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  @Output()
  markedDirty = new EventEmitter<void>();

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        take(1),
        takeUntil(this.destroy),
      )
      .subscribe(() => {
        this.markedDirty.emit();
      });
  }

  get measuringUnitForm(): UntypedFormGroup {
    return this.formGroup.get('measuringUnit') as UntypedFormGroup;
  }

  get ingredientForm(): UntypedFormGroup {
    return this.formGroup.get('ingredient') as UntypedFormGroup;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
