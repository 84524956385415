import {createSelector} from '@ngrx/store';
import {recipeFeature, selectAll, selectEntities} from '../reducers/recipe.reducer';

const selectAllRecipes = createSelector(
  recipeFeature.selectRecipesState,
  selectAll
);

export const selectAllRecipesSorted = createSelector(
  selectAllRecipes,
  r => r.sort((a, b) =>
     a.title > b.title ? 1 : -1
   ),
);

export const selectRecipeEntities = createSelector(
  recipeFeature.selectRecipesState,
  selectEntities,
);

export const selectRecipeById = (id: number) => createSelector(
  selectRecipeEntities,
  a => a[id]
);


export const selectRecipesByIds = (ids: number[]) => createSelector(
  selectRecipeEntities,
  a => ids.map(id => a[id]).filter(recipe => recipe !== undefined)
);
