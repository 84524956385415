import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {ingredientActions} from '../actions/ingredient.actions';
import IngredientDataService from '../services/ingredient-data/ingredient-data.service';

@Injectable()
export class IngredientEffects {
  constructor(
    private actions$: Actions,
    private readonly dataService: IngredientDataService
  ) {}

  loadIngredients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ingredientActions.loadAllIngredients),
      concatMap(() =>
        this.dataService.getAll().pipe(
          map(data => ingredientActions.loadAllIngredientsSuccess({ data })),
          catchError(error => of(ingredientActions.loadAllIngredientsFailure({ error }))))
      )
    )
  );

  addIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ingredientActions.addIngredient),
      map(action => action.data),
      concatMap(ingredient =>
        this.dataService.save(ingredient).pipe(
          map(data => ingredientActions.addIngredientSuccess( { data })),
          catchError(error => of(ingredientActions.addIngredientFailure({ error }))))
        )
      ),
    );

}
