import {createActionGroup, emptyProps, props} from '@ngrx/store';
import Keyword from '@app/model/keyword.model';

export const keywordActions = createActionGroup({
    source: 'Keyword',
    events: {
        'Load All Keywords': emptyProps(),
        'Load All Keywords Success': props<{ data: Keyword[] }>(),
        'Load All Keywords Failure': props<{ error: any }>(),
        'Add Keyword': props<{ data: Keyword }>(),
        'Add Keyword Success': props<{ data: Keyword }>(),
        'Add Keyword Failure': props<{ error: any }>()
    }
});
