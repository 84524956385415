import Keyword, {KeywordInterface} from './keyword.model';
import Step, {StepInterface} from './step.model';
import Yield, {YieldInterface} from '@app/model/yield.model';
import Ingredient from '@app/model/ingredient.model';

export interface RecipeInterface {
  id?: number | null;
  title: string;
  titleTranslation: string;
  description: string;
  source: string;
  cookingTime: number;
  preparationTime: number;
  keywords: KeywordInterface[];
  yield: YieldInterface[];
  steps: StepInterface[];
  createdAt?: string;
  updatedAt?: string;
}

export default class Recipe implements RecipeInterface {
  public id: number | null;
  public title: string;
  public titleTranslation: string;
  public description: string;
  public source: string;
  public cookingTime: number;
  public preparationTime:number;
  public keywords: Keyword[];
  public yield: Yield[];
  public steps: Step[];
  public createdAt: string;
  public updatedAt: string;

  public constructor() {
    this.id = null;
    this.title = '';
    this.titleTranslation = '';
    this.description = '';
    this.source = '';
    this.cookingTime = 0;
    this.preparationTime = 0;
    this.keywords = [];
    this.yield = [];
    this.steps = [];
    this.createdAt =  '';
    this.updatedAt =  '';
  }

  public static fromRecipeInterface(recipeInterface: RecipeInterface): Recipe {
    const recipe = new Recipe();

    recipe.id = recipeInterface.id || null;
    recipe.title = recipeInterface.title;
    recipe.titleTranslation = recipeInterface.titleTranslation;
    recipe.description = recipeInterface.description;
    recipe.source = recipeInterface.source;
    recipe.cookingTime = recipeInterface.cookingTime;
    recipe.preparationTime = recipeInterface.preparationTime;
    recipe.keywords = recipeInterface.keywords.map(keyword => Keyword.fromKeywordInterface(keyword));
    recipe.yield = recipeInterface.yield.map(yieldObject => Yield.fromYieldInterface(yieldObject));
    recipe.steps = recipeInterface.steps.map(step => Step.fromStepInterface(step));
    recipe.createdAt = recipeInterface.createdAt || '';
    recipe.updatedAt = recipeInterface.updatedAt || '';

    return recipe;
  }

  public getIngredients(): Ingredient[] {
    return this.steps
      .flatMap(step => step.getIngredients());
  }
}
