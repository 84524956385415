import {inject, Injectable, InjectionToken} from '@angular/core';
import {environment} from '../../../environments/environment';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage,
});

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly storage = inject(BROWSER_STORAGE);
  private readonly localStorageKeyPrefix: string;

  constructor() {
    this.localStorageKeyPrefix = environment.localStorageKeyPrefix;
  }

  clear(): void {
    const keys: string[] = [];

    for (let index = 0; index < this.storage.length; index++) {
      keys.push(this.storage.key(index));
    }

    for (const key of keys) {
      if(key.startsWith(this.localStorageKeyPrefix)) {
        this.storage.removeItem(key);
      }
    }
  }
}
