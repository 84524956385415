import {createFeature, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {recipeActions} from '../actions/recipe.actions';
import Recipe from '@app/model/recipe.model';

export const recipeFeatureKey = "recipes";

export type RecipeState = EntityState<Recipe>

const adapter: EntityAdapter<Recipe> = createEntityAdapter<Recipe>();

export const initialState: RecipeState = adapter.getInitialState({});

const reducer = createReducer(
  initialState,

  on(
    recipeActions.loadAllRecipes,
    recipeActions.loadAllRecipesFailure,
    recipeActions.addRecipe,
    recipeActions.addRecipeFailure,
    state => state,
  ),
  on(recipeActions.loadAllRecipesSuccess, (state, action) =>
    adapter.setAll(action.data, state),
  ),
  on(recipeActions.addRecipeSuccess, (state, { data: newRecipe }) =>
    adapter.setOne(newRecipe, state)
  ),
);

export const recipeFeature = createFeature({
  name: recipeFeatureKey,
  reducer,
});

export const { selectEntities, selectAll } = adapter.getSelectors();
