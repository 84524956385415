import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Keyword from '@app/model/keyword.model';
import DataService from '../data.service';
import KeywordApiService from '@app/services/api/keyword-api.service';

@Injectable({
  providedIn: 'root'
})
export default class KeywordDataService implements DataService<Keyword> {

  private apiService: KeywordApiService;

  constructor(apiService: KeywordApiService) {
    this.apiService = apiService;
  }

  public getAll(): Observable<Keyword[]> {
    return this.apiService
      .getKeywords();
  }

  public save(keyword: Keyword): Observable<Keyword> {
    return this.apiService.createKeyword(keyword);
  }
}
