<div
  *ngFor="let componentGroup of formArrayFormGroups; let i = index; let first = first; let last = last"
  class="component-list-item-container"
>
  <app-component
    (markedDirty)="addOnLastComponentDirty(last)"
    [formGroup]="componentGroup"
    class="form-field"
    [class.first]="first"
  ></app-component>

  <button
    *ngIf="!last"
    type="button"
    class="button icon-button remove-component-button"
    (click)="remove(i)"
  >
    <mat-icon class="remove-component-button-icon">remove</mat-icon>
  </button>
</div>

