import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import MeasuringUnit from '@app/model/measuring-unit.model';

@Injectable({
  providedIn: 'root'
})
export class MeasuringUnitFormService {
  private builder: UntypedFormBuilder;

  constructor(builder: UntypedFormBuilder) {
    this.builder = builder;
  }

  public init(measuringUnit: MeasuringUnit): UntypedFormGroup {
    const group = this.createGroup();
    group.patchValue({
      id: measuringUnit.id,
      name: measuringUnit.name,
    });

    return group;
  }

  public createGroup(): UntypedFormGroup {
    return this.builder.group({
      id: [null, Validators.required],
      name: ['', [ Validators.required, Validators.maxLength(127) ]],
    });
  }

  public createMeasuringUnit(formGroupValues): MeasuringUnit {
    return MeasuringUnit.fromMeasuringUnitInterface({
      id: Number.parseInt(formGroupValues.id, 10) || null,
      name: formGroupValues.name,
    });
  }

  public isEmpty(formGroupValues): boolean {
    return formGroupValues.name === '';
  }
}
